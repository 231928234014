var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.card.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.equality.js");require("./metabase.lib.expression.js");require("./metabase.lib.join.js");require("./metabase.lib.join.util.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.options.js");require("./metabase.lib.ref.js");require("./metabase.lib.remove_replace.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.lib.temporal_bucket.js");require("./metabase.lib.util.js");require("./metabase.shared.util.i18n.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.humanization.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var l7,n7,o7,rra,sra,tra,r7,s7,u7,v7,ura,w7,vra,wra;l7=function(a){var b=new $CLJS.h(null,3,[$CLJS.zE,$CLJS.p.h($CLJS.JE()),$CLJS.pB,$CLJS.pB.h(a),$CLJS.Ei,$CLJS.xA($CLJS.Ei,$CLJS.pB)(a)],null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.mB,b,$CLJS.xA($CLJS.rE,$CLJS.T)(a)],null)};$CLJS.m7=function(a){return $CLJS.z5.h(a)};n7=function(a){return $CLJS.R.j(a,$CLJS.Vt,$CLJS.Q4)};
o7=function(a,b){return $CLJS.E.g($CLJS.lE.h(a),$CLJS.cm(b,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.DE,$CLJS.kE,$CLJS.CE],null)))};rra=function(a){return $CLJS.n1(a,new $CLJS.h(null,1,[$CLJS.DE,$CLJS.zh],null))};
sra=function(a,b){var c=$CLJS.Re(function(e){return $CLJS.tH(function(f){return $CLJS.E.g($CLJS.J.g(f,e),a)},b)},new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.IZ,$CLJS.T],null));if($CLJS.n(c))return c;c=$CLJS.FA($CLJS.Dy);if($CLJS.n($CLJS.EA("metabase.lib.field",c))){var d=$CLJS.oE("Invalid :field clause: column {0} does not exist. Found: {1}",$CLJS.H([$CLJS.Mh.l($CLJS.H([a])),$CLJS.Mh.l($CLJS.H([$CLJS.Pk.g($CLJS.IZ,b)]))]));d instanceof Error?$CLJS.OA("metabase.lib.field",c,$CLJS.zw(),d):$CLJS.OA("metabase.lib.field",
c,$CLJS.zw.l($CLJS.H([d])),null)}return null};
tra=function(a,b,c){if($CLJS.n(p7))return null;var d=p7;p7=!0;try{var e=$CLJS.Z1(a,b),f=$CLJS.n(e)?$CLJS.CW(a,e):$CLJS.CW(a,b),k=function(){var m=$CLJS.q7.h(f);if($CLJS.n(m))return m;m=$CLJS.yA(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mV,$CLJS.yW],null));if($CLJS.n(m))return m;m=$CLJS.n(function(){var u=$CLJS.JV.h(f);if($CLJS.n(u))return u;u=$CLJS.dP.h(f);if($CLJS.n(u))return u;u=$CLJS.YP.h(f);return $CLJS.n(u)?u:$CLJS.SE.h(f)}())?$CLJS.J0.j(a,b,f):null;if($CLJS.n(m))return m;m=$CLJS.FA($CLJS.Dy);if($CLJS.n($CLJS.EA("metabase.lib.field",
m))){var t=$CLJS.oE("Cannot resolve column {0}: stage has no metadata",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))]));return t instanceof Error?$CLJS.OA("metabase.lib.field",m,$CLJS.zw(),t):$CLJS.OA("metabase.lib.field",m,$CLJS.zw.l($CLJS.H([t])),null)}return null}(),l=function(){var m=$CLJS.y(k);return m?sra(c,k):m}();return $CLJS.n(l)?$CLJS.n(e)?$CLJS.R.j($CLJS.R.j($CLJS.z3($CLJS.Fk.l(l,$CLJS.Zi,$CLJS.H([$CLJS.$K,$CLJS.NW,$CLJS.HW])),null),$CLJS.T,function(){var m=$CLJS.IZ.h(l);return $CLJS.n(m)?m:$CLJS.T.h(l)}()),
$CLJS.PK,$CLJS.vZ):l:null}finally{p7=d}};
r7=function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null),e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.sP);var f=$CLJS.I(c,2,null);c=$CLJS.ll.l($CLJS.H([function(){var k=$CLJS.pB.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.pB,k],null):null}(),function(){var k=$CLJS.xA($CLJS.Ei,$CLJS.pB)(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.Ei,k],null):null}(),function(){var k=$CLJS.tR.h(e);return $CLJS.n(k)?new $CLJS.h(null,1,[$CLJS.NW,k],null):null}(),function(){var k=$CLJS.oG.h(e);return $CLJS.n(k)?new $CLJS.h(null,
1,[$CLJS.HW,k],null):null}(),$CLJS.Fd(f)?function(){var k=$CLJS.d2(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vt,$CLJS.NK,$CLJS.T,f],null)}():$CLJS.n(d)?new $CLJS.h(null,2,[$CLJS.Vt,$CLJS.NK,$CLJS.T,f],null):function(){var k=tra(a,b,f);return $CLJS.n(k)?k:new $CLJS.h(null,2,[$CLJS.Vt,$CLJS.NK,$CLJS.T,f],null)}()]));return $CLJS.n(d)?$CLJS.z3(c,d):c};s7=function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.HW);return $CLJS.n($CLJS.n(b)?$CLJS.Hd($CLJS.rH,b):b)?$CLJS.wj:$CLJS.xA($CLJS.Ei,$CLJS.pB)(a)};
$CLJS.t7=function(a,b,c,d){$CLJS.I(d,0,null);var e=$CLJS.I(d,1,null),f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.zE),l=$CLJS.J.g(f,$CLJS.pB),m=$CLJS.J.g(f,$CLJS.tR),t=$CLJS.J.g(f,$CLJS.Ei);e=$CLJS.J.g(f,$CLJS.sP);var u=$CLJS.J.g(f,$CLJS.DN),v=$CLJS.J.g(f,$CLJS.oG),x=$CLJS.ll.l;k=new $CLJS.h(null,2,[$CLJS.Vt,$CLJS.NK,$CLJS.a1,k],null);f=$CLJS.mE.h(f);a=$CLJS.n(f)?f:$CLJS.Z_.j(a,b,d);c=x.call($CLJS.ll,$CLJS.H([k,c,new $CLJS.h(null,1,[$CLJS.mE,a],null)]));c=$CLJS.n(t)?$CLJS.R.j(c,$CLJS.Ei,t):c;l=$CLJS.n(l)?$CLJS.R.j(c,
$CLJS.pB,l):c;v=$CLJS.n(v)?$CLJS.R.j(l,$CLJS.HW,v):l;m=$CLJS.n(m)?$CLJS.R.j(v,$CLJS.NW,m):v;u=$CLJS.n(u)?$CLJS.R.j(m,$CLJS.ZK,u):m;return $CLJS.n(e)?$CLJS.z3(u,e):u};u7=function(a,b,c){return $CLJS.n($CLJS.Re(function(d){return $CLJS.E.g($CLJS.ri.h(d),c)},a))?$CLJS.Pk.g(function(d){var e=$CLJS.Hd(d,b)?$CLJS.Fk.l(d,d,$CLJS.H([b])):d;return $CLJS.E.g($CLJS.ri.h(d),c)?$CLJS.R.j(e,b,!0):e},a):a};
v7=function(a){var b=$CLJS.n($CLJS.I1.h(a))?null:function(){var d=$CLJS.PK.h(a),e=new $CLJS.ah(null,new $CLJS.h(null,3,[$CLJS.MZ,null,$CLJS.SZ,null,$CLJS.vZ,null],null),null);return e.h?e.h(d):e.call(null,d)}(),c=$CLJS.ll.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.zE,$CLJS.p.h($CLJS.JE()),$CLJS.pB,$CLJS.pB.h(a),$CLJS.Ei,s7(a)],null),function(){var d=$CLJS.B1(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.sP,d],null):null}(),function(){var d=$CLJS.HW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.oG,d],
null):null}(),function(){var d=$CLJS.LW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.LW,d],null):null}(),function(){var d=$CLJS.NW.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.tR,d],null):null}(),function(){var d=$CLJS.ZK.h(a);return $CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.DN,d],null):null}()]));b=($CLJS.n(b)?$CLJS.xA($CLJS.IZ,$CLJS.T):$CLJS.xA($CLJS.Zi,$CLJS.T))(a);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,c,b],null)};
ura=function(a,b){return $CLJS.lf(function(c){return $CLJS.E.g($CLJS.PK.h(c),$CLJS.CZ)},$CLJS.J0.v(a,b,$CLJS.CW(a,b),new $CLJS.h(null,3,[$CLJS.r0,!1,$CLJS.x0,!0,$CLJS.n0,!1],null)))};w7=new $CLJS.M(null,"parent-id","parent-id",-1400729131);$CLJS.q7=new $CLJS.M("metabase.lib.stage","cached-metadata","metabase.lib.stage/cached-metadata",-1406479151);vra=new $CLJS.M(null,"earliest","earliest",-1928154382);wra=new $CLJS.M(null,"latest","latest",24323665);var zra;$CLJS.o1.m(null,$CLJS.iG,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zh.h(b),$CLJS.n1(c,new $CLJS.h(null,2,[$CLJS.oG,$CLJS.zh,$CLJS.tR,rra],null)),a],null)});var p7=!1,x7=function x7(a,b){var d=$CLJS.u1(a,w7.h(b));a=$CLJS.n(w7.h(d))?x7.g?x7.g(a,d):x7.call(null,a,d):d;a=$CLJS.O(a);var e=$CLJS.J.g(a,$CLJS.T);return $CLJS.Qk.j(b,$CLJS.T,function(f){return[$CLJS.p.h(e),".",$CLJS.p.h(f)].join("")})};
$CLJS.C0.m(null,$CLJS.NK,function(a,b,c){return s7(c)});$CLJS.C0.m(null,$CLJS.iG,function(a,b,c){$CLJS.I(c,0,null);var d=$CLJS.I(c,1,null);d=$CLJS.O(d);d=$CLJS.J.g(d,$CLJS.oG);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(d)?$CLJS.R.j(c,$CLJS.HW,d):c;return $CLJS.D0.j(a,b,c)});$CLJS.E0.m(null,$CLJS.NK,function(a,b,c){a=$CLJS.O(c);b=$CLJS.J.g(a,$CLJS.T);return $CLJS.R.j(a,$CLJS.T,b)});$CLJS.E0.m(null,$CLJS.iG,function(a,b,c){var d=r7(a,b,c);b=$CLJS.t7(a,b,d,c);return $CLJS.n(w7.h(b))?x7(a,b):b});
$CLJS.y0.m(null,$CLJS.NK,function(a,b,c,d){c=$CLJS.O(c);var e=$CLJS.J.g(c,$CLJS.mE),f=$CLJS.J.g(c,$CLJS.T),k=$CLJS.J.g(c,$CLJS.ri),l=$CLJS.J.g(c,$CLJS.NW),m=$CLJS.J.g(c,$CLJS.VZ),t=$CLJS.J.g(c,$CLJS.ZK),u=$CLJS.J.g(c,$CLJS.$K);e=$CLJS.n(e)?e:"string"===typeof f?$CLJS.z1.g($CLJS.vB,f):$CLJS.p.h(f);$CLJS.E.g(d,$CLJS.$_)&&-1==e.indexOf(" → ")?($CLJS.n(t)?(t=$CLJS.u1(a,t),$CLJS.n(t)?a=(0,$CLJS.ma)($CLJS.KA($CLJS.mE.h($CLJS.b0.j(a,b,t)),$CLJS.PW,"")):(u=$CLJS.A1(a,u),a=$CLJS.Z_.v(a,b,u,d))):a=null,m=$CLJS.n(a)?
a:$CLJS.n(m)?m:$CLJS.B1(c)):m=null;m=$CLJS.n(m)?[$CLJS.p.h(m)," → ",$CLJS.p.h(e)].join(""):e;return $CLJS.n(k)?(c=$CLJS.ZA($CLJS.KA($CLJS.gh(k),"-"," ")),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):$CLJS.n(l)?(c=$CLJS.w5(l,c),$CLJS.fa.j?$CLJS.fa.j("%s: %s",m,c):$CLJS.fa.call(null,"%s: %s",m,c)):m});
$CLJS.y0.m(null,$CLJS.iG,function(a,b,c,d){$CLJS.I(c,0,null);var e=$CLJS.I(c,1,null),f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.tR);var k=$CLJS.J.g(f,$CLJS.sP),l=$CLJS.J.g(f,$CLJS.oG);f=$CLJS.J.g(f,$CLJS.DN);$CLJS.I(c,2,null);c=r7(a,b,c);c=$CLJS.n(k)?$CLJS.R.j(c,$CLJS.VZ,k):c;l=$CLJS.n(l)?$CLJS.R.j(c,$CLJS.ri,l):c;e=$CLJS.n(e)?$CLJS.R.j(l,$CLJS.NW,e):l;e=$CLJS.n(f)?$CLJS.R.j(e,$CLJS.ZK,f):e;return $CLJS.n(e)?$CLJS.Z_.v(a,b,e,d):$CLJS.QE("[Unknown Field]")});
$CLJS.z0.m(null,$CLJS.NK,function(a,b,c){a=$CLJS.O(c);return $CLJS.J.g(a,$CLJS.T)});$CLJS.z0.m(null,$CLJS.iG,function(a,b,c){$CLJS.I(c,0,null);$CLJS.I(c,1,null);c=r7(a,b,c);return $CLJS.n(c)?$CLJS.A0.j(a,b,c):"unknown_field"});
$CLJS.F0.m(null,$CLJS.NK,function(a,b,c){return $CLJS.ll.l($CLJS.H([function(){var d=$CLJS.nI($CLJS.F0,$CLJS.ci);return d.j?d.j(a,b,c):d.call(null,a,b,c)}(),$CLJS.E.g($CLJS.PK.h(c),$CLJS.MZ)?function(){var d=$CLJS.UZ.h(c);return $CLJS.n(d)?(d=$CLJS.V_(a,d),$CLJS.n(d)?new $CLJS.h(null,1,[$CLJS.MW,new $CLJS.h(null,2,[$CLJS.T,$CLJS.T.h(d),$CLJS.mE,$CLJS.T.h(d)],null)],null):null):null}():null]))});$CLJS.T0.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);$CLJS.I(a,2,null);return $CLJS.oG.h(b)});
$CLJS.T0.m(null,$CLJS.NK,function(a){return $CLJS.HW.h(a)});
$CLJS.S0.m(null,$CLJS.iG,function(a,b){$CLJS.I(a,0,null);var c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);if($CLJS.n(b)){var d=$CLJS.Hd($CLJS.rH,b),e=$CLJS.jC($CLJS.LW,$CLJS.Ei,$CLJS.pB)(c);c=$CLJS.R.l(c,$CLJS.oG,b,$CLJS.H([$CLJS.Ei,d?$CLJS.wj:e,$CLJS.LW,e]));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,c,a],null)}b=$CLJS.LW.h(c);c=$CLJS.n(b)?$CLJS.Fk.g($CLJS.R.j(c,$CLJS.Ei,b),$CLJS.LW):c;c=$CLJS.Fk.g(c,$CLJS.oG);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,c,a],null)});
$CLJS.S0.m(null,$CLJS.NK,function(a,b){return $CLJS.n(b)?$CLJS.R.l(a,$CLJS.HW,b,$CLJS.H([$CLJS.LW,$CLJS.jC($CLJS.LW,$CLJS.Ei,$CLJS.pB)(a)])):$CLJS.Fk.l(a,$CLJS.HW,$CLJS.H([$CLJS.LW]))});$CLJS.V0.m(null,$CLJS.iG,function(a,b,c){return $CLJS.W0.j(a,b,r7(a,b,c))});
$CLJS.V0.m(null,$CLJS.NK,function(a,b,c){if($CLJS.Wk.g($CLJS.PK.h(c),$CLJS.CZ)){a=$CLJS.xA($CLJS.Ei,$CLJS.pB)(c);b=null==c?null:$CLJS.RN.h(c);if(null==b)var d=null;else try{var e=$CLJS.rD.h($CLJS.mj.h(b));if($CLJS.n(e)){var f=$CLJS.O(e),k=$CLJS.J.g(f,vra),l=$CLJS.J.g(f,wra),m=$CLJS.Fna.l($CLJS.H([$CLJS.Q0.h(k),$CLJS.Q0.h(l)]));d=isNaN(m)?null:$CLJS.n($CLJS.Pr.g?$CLJS.Pr.g(1,m):$CLJS.Pr.call(null,1,m))?$CLJS.Ui:$CLJS.n($CLJS.Pr.g?$CLJS.Pr.g(31,m):$CLJS.Pr.call(null,31,m))?$CLJS.Wt:$CLJS.n($CLJS.Pr.g?
$CLJS.Pr.g(365,m):$CLJS.Pr.call(null,365,m))?$CLJS.oj:$CLJS.xj}else d=null}catch(t){if(t instanceof Error)d=null;else throw t;}e=$CLJS.yC(a,$CLJS.rD)?$CLJS.Nna:$CLJS.yC(a,$CLJS.SC)?$CLJS.Mna:$CLJS.yC(a,$CLJS.RD)?$CLJS.Lna:$CLJS.Cf;d=$CLJS.n(d)?u7(e,$CLJS.ci,d):e;return $CLJS.n($CLJS.HW.h(c))?u7(d,$CLJS.g0,$CLJS.HW.h(c)):d}return $CLJS.Cf});
$CLJS.z5.m(null,$CLJS.iG,function(a){var b=null==a?null:$CLJS.GE(a);b=null==b?null:$CLJS.tR.h(b);return null==b?null:$CLJS.R.l(b,$CLJS.Vt,$CLJS.y5,$CLJS.H([$CLJS.x5,function(c,d){return r7(c,d,a)}]))});$CLJS.z5.m(null,$CLJS.NK,function(a){var b=null==a?null:$CLJS.NW.h(a);return null==b?null:$CLJS.R.l(b,$CLJS.Vt,$CLJS.y5,$CLJS.H([$CLJS.x5,$CLJS.Ue(a)]))});$CLJS.t5.m(null,$CLJS.iG,function(a,b){return $CLJS.HE(a,$CLJS.FE,$CLJS.H([$CLJS.tR,b]))});
$CLJS.t5.m(null,$CLJS.NK,function(a,b){return $CLJS.FE(a,$CLJS.NW,b)});$CLJS.A5.m(null,$CLJS.iG,function(a,b,c){return $CLJS.B5.j(a,b,r7(a,b,c))});
$CLJS.A5.m(null,$CLJS.NK,function(a,b,c){b=$CLJS.O(c);c=$CLJS.J.g(b,$CLJS.Ei);var d=$CLJS.J.g(b,$CLJS.RN),e=$CLJS.J.g(b,$CLJS.Vj);if($CLJS.Wk.g($CLJS.PK.h(b),$CLJS.CZ)){var f=function(){var m=null==a?null:$CLJS.s1($CLJS.S_(a));m=null==m?null:$CLJS.eA.h(m);return null==m?null:$CLJS.Hd(m,$CLJS.tR)}(),k=$CLJS.yA(d,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj,$CLJS.Pj],null)),l=$CLJS.m7(b);return function u(t){return new $CLJS.ne(null,function(){for(;;){var v=$CLJS.y(t);if(v){if($CLJS.Ad(v)){var x=$CLJS.lc(v),
z=$CLJS.D(x),C=$CLJS.qe(z);return function(){for(var K=0;;)if(K<z){var S=$CLJS.kd(x,K),V=C,Z=S;S=o7(S,l)?$CLJS.R.j(Z,$CLJS.g0,!0):Z;V.add(S);K+=1}else return!0}()?$CLJS.te($CLJS.ve(C),u($CLJS.mc(v))):$CLJS.te($CLJS.ve(C),null)}var G=$CLJS.A(v);return $CLJS.ee(function(){var K=G;return o7(G,l)?$CLJS.R.j(K,$CLJS.g0,!0):K}(),u($CLJS.Lc(v)))}return null}},null,null)}($CLJS.Va(function(){if($CLJS.n(f)){var t=$CLJS.$n.h(k);return $CLJS.n(t)?$CLJS.jk.h(k):t}return f}())?null:$CLJS.yC(e,$CLJS.yj)?$CLJS.Pk.g(n7,
new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.v5(),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.QE("Bin every 0.1 degrees"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.DE,$CLJS.CE,$CLJS.CE,.1],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.QE("Bin every 1 degree"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.DE,$CLJS.CE,$CLJS.CE,1],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.QE("Bin every 10 degrees"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.DE,$CLJS.CE,$CLJS.CE,10],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.QE("Bin every 20 degrees"),
$CLJS.lE,new $CLJS.h(null,2,[$CLJS.DE,$CLJS.CE,$CLJS.CE,20],null)],null)],null)):$CLJS.yC(c,$CLJS.Pj)&&!$CLJS.yC(e,$CLJS.mi)?$CLJS.Pk.g(n7,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.v5(),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.QE("10 bins"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.DE,$CLJS.kE,$CLJS.kE,10],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.QE("50 bins"),$CLJS.lE,new $CLJS.h(null,2,[$CLJS.DE,$CLJS.kE,$CLJS.kE,50],null)],null),new $CLJS.h(null,2,[$CLJS.mE,$CLJS.QE("100 bins"),$CLJS.lE,new $CLJS.h(null,2,
[$CLJS.DE,$CLJS.kE,$CLJS.kE,100],null)],null)],null)):null)}return $CLJS.Cf});$CLJS.mW.m(null,$CLJS.iG,function(a){return a});
$CLJS.mW.m(null,$CLJS.NK,function(a){a=$CLJS.O(a);var b=$CLJS.J.g(a,$CLJS.PK);switch(b instanceof $CLJS.M?b.T:null){case "source/aggregations":return b=new $CLJS.h(null,2,[$CLJS.zE,$CLJS.p.h($CLJS.JE()),$CLJS.Ei,$CLJS.xA($CLJS.Ei,$CLJS.pB)(a)],null),a=$CLJS.a1.h(a),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.YF,b,a],null);case "source/expressions":return l7(a);case "source/fields":case "source/breakouts":return $CLJS.n($CLJS.rE.h(a))?l7(a):v7(a);default:return v7(a)}});
$CLJS.xra=function(){function a(e,f,k){k=$CLJS.Fe($CLJS.Pk.g($CLJS.oW,k));var l=ura(e,f),m=$CLJS.eg.j($CLJS.bh,$CLJS.Yl(function(t){return $CLJS.j2.v(e,f,t,l)}),$CLJS.n(k)?k:$CLJS.Cf);m=$CLJS.Ok.g(m,l);k=$CLJS.n(k)?$CLJS.eg.j(k,$CLJS.hf.h($CLJS.oW),m):null;return $CLJS.OW.l(e,f,$CLJS.FE,$CLJS.H([$CLJS.SE,k]))}function b(e,f){return d.j?d.j(e,-1,f):d.call(null,e,-1,f)}function c(e){return function(f,k){return d.j?d.j(f,k,e):d.call(null,f,k,e)}}var d=null;d=function(e,f,k){switch(arguments.length){case 1:return c.call(this,
e);case 2:return b.call(this,e,f);case 3:return a.call(this,e,f,k)}throw Error("Invalid arity: "+arguments.length);};d.h=c;d.g=b;d.j=a;return d}();$CLJS.y7=function(){function a(d,e){return $CLJS.SE.h($CLJS.CW(d,e))}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.yra=function(){function a(d,e){var f=$CLJS.J0.v(d,e,$CLJS.CW(d,e),new $CLJS.h(null,3,[$CLJS.r0,!1,$CLJS.x0,!1,$CLJS.n0,!1],null)),k=$CLJS.y7.g(d,e);return $CLJS.td(k)?$CLJS.Pk.g(function(l){return $CLJS.R.j(l,$CLJS.KZ,!0)},f):$CLJS.x3(d,e,f,k)}function b(d){return c.g?c.g(d,-1):c.call(null,d,-1)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
zra=function(){function a(d,e,f){var k=$CLJS.CW(d,e),l=$CLJS.E.g($CLJS.dC(f),$CLJS.YF)?$CLJS.H0:$CLJS.J0;k=l.j?l.j(d,e,k):l.call(null,d,e,k);return $CLJS.j2.v(d,e,f,k)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.Ara=function(){function a(d,e,f){f=$CLJS.rZ.j(d,e,f);return zra.j(d,e,f)}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();