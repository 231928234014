var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./medley.core.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.types.js");require("./metabase.util.malli.registry.js");
'use strict';var uH,vH,xH,yH,Pfa,Qfa,Rfa,zH,wH;$CLJS.BH=function(a,b){return $CLJS.gc($CLJS.fb(function(c,d){var e=a.h?a.h(d):a.call(null,d);return $CLJS.hg.j(c,e,$CLJS.be.g($CLJS.J.j(c,e,$CLJS.Cf),d))},$CLJS.ec($CLJS.N),b))};$CLJS.tH=function(a,b){return $CLJS.fb(function(c,d){return $CLJS.n(a.h?a.h(d):a.call(null,d))?$CLJS.Sc(d):null},null,b)};
uH=function(a,b){$CLJS.I(a,0,null);$CLJS.I(a,1,null);$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);b=$CLJS.yC(b,$CLJS.SC)?$CLJS.eH:$CLJS.yC(b,$CLJS.RD)?$CLJS.ZG:$CLJS.yC(b,$CLJS.rD)?$CLJS.fE:null;return $CLJS.n(b)?$CLJS.pF(b,a):!0};
vH=function(a){a=$CLJS.y(a);$CLJS.A(a);a=$CLJS.B(a);$CLJS.A(a);a=$CLJS.B(a);a=$CLJS.BH(function(d){return $CLJS.yC($CLJS.ME(d),$CLJS.cD)},a);var b=$CLJS.O(a);a=$CLJS.J.g(b,!1);b=$CLJS.J.g(b,!0);if($CLJS.Wk.g($CLJS.D(a),1))return"Temporal arithmetic expression must contain exactly one non-interval value";if(1>$CLJS.D(b))return"Temporal arithmetic expression must contain at least one :interval";var c=$CLJS.ME($CLJS.A(a));return $CLJS.Re(function(d){$CLJS.I(d,0,null);$CLJS.I(d,1,null);$CLJS.I(d,2,null);
var e=$CLJS.I(d,3,null);return $CLJS.n(uH(d,c))?null:["Cannot add a ",$CLJS.p.h(e)," interval to a ",$CLJS.p.h(c)," expression"].join("")},b)};
xH=function(a){return new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ws,new $CLJS.h(null,1,[$CLJS.pt,[$CLJS.p.h(a)," clause with a temporal expression and one or more :interval clauses"].join("")],null),new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wH],null)],
null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ds,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,wH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.rt,function(b){b=$CLJS.O(b);b=$CLJS.J.g(b,$CLJS.Dj);return["Invalid ",$CLJS.p.h(a)," clause: ",$CLJS.p.h(vH(b))].join("")}],null),$CLJS.Te(vH)],null)],null)};
yH=function(a){return new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.pt,[$CLJS.p.h(a)," clause with numeric args"].join("")],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,a],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null)],null)};
Pfa=function(a){return $CLJS.Qd($CLJS.sF,$CLJS.hf.g(function(b){var c=$CLJS.ME(b),d=$CLJS.yC(c,$CLJS.ZE);b=d?$CLJS.pF($CLJS.mG,b):d;return $CLJS.n(b)?$CLJS.Pj:c},a))};Qfa=function(a){a=$CLJS.tH(function(b){return!$CLJS.yC(b,$CLJS.cD)},$CLJS.hf.g($CLJS.ME,a));return $CLJS.yC(a,$CLJS.ZE)?$CLJS.Wj:a};
Rfa=function(a,b){return $CLJS.n($CLJS.Re(function(c){return $CLJS.yC($CLJS.ME(c),$CLJS.cD)},b))?Qfa(b):$CLJS.E.g(a,$CLJS.ht)&&$CLJS.E.g($CLJS.D(b),2)&&($CLJS.Qe(function(c){return $CLJS.yC($CLJS.ME(c),$CLJS.SC)},b)||$CLJS.Qe(function(c){return $CLJS.yC($CLJS.ME(c),$CLJS.rD)},b))?$CLJS.cD:Pfa(b)};zH=new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.numbers","metabase.lib.schema.expression.arithmetic/args.numbers",-179216621);
$CLJS.AH=new $CLJS.M("lib.type-of","type-is-type-of-arithmetic-args","lib.type-of/type-is-type-of-arithmetic-args",-626871615);wH=new $CLJS.M("mbql.clause","interval","mbql.clause/interval",-990446354);$CLJS.X(new $CLJS.M("metabase.lib.schema.expression.arithmetic","args.temporal","metabase.lib.schema.expression.arithmetic/args.temporal",1338604923),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ii,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[new $CLJS.M(null,"intervals","intervals",2096054013),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Wr,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,wH],null)],null)],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,"Temporal arithmetic expression with valid interval units for the expression type"],null),function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);var c=$CLJS.ME(a);return $CLJS.Qe(function(d){return uH(d,c)},b)}],null)],null));
$CLJS.X(zH,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)],null));
var Sfa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.Fj,new $CLJS.h(null,1,[$CLJS.pt,":- clause taking the difference of two temporal expressions"],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.ht],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.xE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null)],null)],
null);$CLJS.IG.g($CLJS.Wr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,xH($CLJS.Wr),yH($CLJS.Wr)],null));$CLJS.IG.g($CLJS.ht,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ps,xH($CLJS.ht),Sfa,yH($CLJS.ht)],null));$CLJS.zF($CLJS.Xr,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,zH],null)]));$CLJS.zF($CLJS.DG,$CLJS.H([$CLJS.ht,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,zH],null)]));
for(var CH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Wr,$CLJS.ht,$CLJS.Xr],null)),DH=null,EH=0,FH=0;;)if(FH<EH){var Tfa=DH.X(null,FH);$CLJS.qF(Tfa,$CLJS.AH);FH+=1}else{var GH=$CLJS.y(CH);if(GH){var HH=GH;if($CLJS.Ad(HH)){var IH=$CLJS.lc(HH),Ufa=$CLJS.mc(HH),Vfa=IH,Wfa=$CLJS.D(IH);CH=Ufa;DH=Vfa;EH=Wfa}else{var Xfa=$CLJS.A(HH);$CLJS.qF(Xfa,$CLJS.AH);CH=$CLJS.B(HH);DH=null;EH=0}FH=0}else break}
$CLJS.LE.m(null,$CLJS.AH,function(a){var b=$CLJS.y(a);a=$CLJS.A(b);b=$CLJS.B(b);$CLJS.A(b);b=$CLJS.B(b);return Rfa(a,b)});$CLJS.xF($CLJS.JF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));$CLJS.qF($CLJS.JF,$CLJS.cF);
for(var JH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.FG,$CLJS.AF,$CLJS.WF],null)),KH=null,LH=0,MH=0;;)if(MH<LH){var Yfa=KH.X(null,MH);$CLJS.xF(Yfa,$CLJS.H([$CLJS.ht,$CLJS.OD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));MH+=1}else{var NH=$CLJS.y(JH);if(NH){var OH=NH;if($CLJS.Ad(OH)){var PH=$CLJS.lc(OH),Zfa=$CLJS.mc(OH),$fa=PH,aga=$CLJS.D(PH);JH=Zfa;KH=$fa;LH=aga}else{var bga=$CLJS.A(OH);$CLJS.xF(bga,$CLJS.H([$CLJS.ht,$CLJS.OD,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));JH=$CLJS.B(OH);KH=null;LH=0}MH=0}else break}
for(var QH=$CLJS.y(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.EF,$CLJS.gG,$CLJS.nG],null)),RH=null,SH=0,TH=0;;)if(TH<SH){var cga=RH.X(null,TH);$CLJS.xF(cga,$CLJS.H([$CLJS.ht,$CLJS.wj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));TH+=1}else{var UH=$CLJS.y(QH);if(UH){var VH=UH;if($CLJS.Ad(VH)){var WH=$CLJS.lc(VH),dga=$CLJS.mc(VH),ega=WH,fga=$CLJS.D(WH);QH=dga;RH=ega;SH=fga}else{var gga=$CLJS.A(VH);$CLJS.xF(gga,$CLJS.H([$CLJS.ht,$CLJS.wj,new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));QH=$CLJS.B(VH);RH=null;SH=0}TH=0}else break}$CLJS.xF($CLJS.GF,$CLJS.H([new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.iF],null)],null)]));
$CLJS.LE.m(null,$CLJS.GF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);var b=$CLJS.I(a,2,null);a=$CLJS.I(a,3,null);return $CLJS.yC($CLJS.ME(b),$CLJS.wj)&&$CLJS.yC($CLJS.ME(a),$CLJS.wj)?$CLJS.wj:$CLJS.OD});