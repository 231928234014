var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.string.js");require("./clojure.walk.js");require("./medley.core.js");require("./metabase.lib.cache.js");require("./metabase.lib.convert.js");require("./metabase.lib.core.js");require("./metabase.lib.equality.js");require("./metabase.lib.join.js");require("./metabase.lib.js.metadata.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.metadata.protocols.js");require("./metabase.lib.order_by.js");require("./metabase.lib.stage.js");require("./metabase.lib.util.js");require("./metabase.mbql.js.js");require("./metabase.mbql.normalize.js");require("./metabase.shared.util.time.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.memoize.js");
'use strict';var Fwa=function(a,b){const c={};for(const d in a)b.call(void 0,a[d],d,a)&&(c[d]=a[d]);return c},Gwa=function(a,b){const c={};for(const d in a)c[d]=b.call(void 0,a[d],d,a);return c},Hwa=function(a,b,c,d){var e=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(f,k){return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),d);return function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var z=$CLJS.kd(t,
x);z=$CLJS.j2.v(a,b,z,d);z=$CLJS.J.j(e,z,-1);v.add(z);x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.A(m);v=$CLJS.j2.v(a,b,v,d);return $CLJS.ee($CLJS.J.j(e,v,-1),l($CLJS.Lc(m)))}return null}},null,null)}(c)},B9=function(a,b){return new $CLJS.Jh(function(){try{return $CLJS.x9.g(a,b)}catch(e){if(e instanceof Error){var c=e,d=$CLJS.FA($CLJS.xy);$CLJS.n($CLJS.EA("metabase.lib.js.metadata",d))&&(c instanceof Error?$CLJS.OA("metabase.lib.js.metadata",
d,$CLJS.rW("Error parsing %s objects: %s",$CLJS.H([a,$CLJS.AV(c)])),c):$CLJS.OA("metabase.lib.js.metadata",d,$CLJS.rW(c,$CLJS.H(["Error parsing %s objects: %s",a,$CLJS.AV(c)])),null));return null}throw e;}})},Iwa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==
K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.$K.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.$K.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.SE.h(a);
return null==d?null:$CLJS.q(d)}())},Jwa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.$K.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):
$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=v;return $CLJS.n(x)?$CLJS.E.g($CLJS.$K.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.s9.h(a);return null==d?null:$CLJS.q(d)}())},Kwa=function(a,b){return function e(d){return new $CLJS.ne(null,function(){for(var f=d;;)if(f=$CLJS.y(f)){if($CLJS.Ad(f)){var k=
$CLJS.lc(f),l=$CLJS.D(k),m=$CLJS.qe(l);return function(){for(var x=0;;)if(x<l){var z=$CLJS.kd(k,x);$CLJS.I(z,0,null);var C=$CLJS.I(z,1,null),G=function(){var K=C;return null==K?null:$CLJS.q(K)}();$CLJS.n(function(){var K=G;return $CLJS.n(K)?$CLJS.E.g($CLJS.$K.h(G),b):K}())&&m.add(G);x+=1}else return!0}()?$CLJS.te($CLJS.ve(m),e($CLJS.mc(f))):$CLJS.te($CLJS.ve(m),null)}var t=$CLJS.A(f);$CLJS.I(t,0,null);var u=$CLJS.I(t,1,null),v=function(){var x=u;return null==x?null:$CLJS.q(x)}();if($CLJS.n(function(){var x=
v;return $CLJS.n(x)?$CLJS.E.g($CLJS.$K.h(v),b):x}()))return $CLJS.ee(v,e($CLJS.Lc(f)));f=$CLJS.Lc(f)}else return null},null,null)}(function(){var d=null==a?null:$CLJS.t9.h(a);return null==d?null:$CLJS.q(d)}())},C9=function(a,b,c,d){this.fg=a;this.Ii=b;this.tc=c;this.ji=d;this.C=393216;this.I=0},Lwa=function(a){return $CLJS.Xa(a)?Fwa(a,function(b){return void 0!==b}):a},D9=function(a){return $CLJS.wt($CLJS.Bz(Gwa(a,function(b){return Lwa(b)})),function(b){return $CLJS.Qk.j($CLJS.zA(b,$CLJS.zh),$CLJS.mj,
$CLJS.zh)})},E9=function(a,b){if($CLJS.tZ(b))a=b;else{var c=new $CLJS.h(null,6,[$CLJS.w9,B9($CLJS.TP,b),$CLJS.u9,B9($CLJS.MW,b),$CLJS.SE,B9($CLJS.iG,b),$CLJS.r9,B9($CLJS.uN,b),$CLJS.s9,B9($CLJS.dG,b),$CLJS.t9,B9($CLJS.HF,b)],null),d=$CLJS.FA($CLJS.uy);$CLJS.n($CLJS.EA("metabase.lib.js.metadata",d))&&$CLJS.OA("metabase.lib.js.metadata",d,$CLJS.zw.l($CLJS.H(["Created metadata provider for metadata"])),null);a=new C9(a,b,c,$CLJS.N)}return a},H9=function(a){return $CLJS.xd(a)?F9.h?F9.h(a):F9.call(null,
a):$CLJS.wd(a)?G9.h?G9.h(a):G9.call(null,a):a instanceof $CLJS.M?$CLJS.WA(a):a},I9=function(a,b){return $CLJS.aX($CLJS.aX((0,$CLJS.IY)($CLJS.A9($CLJS.Bz(a))),$CLJS.hQ,function(c){return $CLJS.ll.l($CLJS.H([new $CLJS.h(null,1,[$CLJS.FQ,$CLJS.N],null),c]))}),$CLJS.FF,function(c){var d=function(){var e=$CLJS.SE.h(c);return $CLJS.n(e)?e:function l(k){return new $CLJS.ne(null,function(){for(;;){var m=$CLJS.y(k);if(m){if($CLJS.Ad(m)){var t=$CLJS.lc(m),u=$CLJS.D(t),v=$CLJS.qe(u);a:for(var x=0;;)if(x<u){var z=
$CLJS.kd(t,x);v.add(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,z,null],null));x+=1}else{t=!0;break a}return t?$CLJS.te($CLJS.ve(v),l($CLJS.mc(m))):$CLJS.te($CLJS.ve(v),null)}v=$CLJS.A(m);return $CLJS.ee(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,v,null],null),l($CLJS.Lc(m)))}return null}},null,null)}(b)}();return $CLJS.R.j(c,$CLJS.SE,$CLJS.c8(d))})},Mwa=function(a,b){var c=$CLJS.CW(a,b);return $CLJS.we($CLJS.hf.g(function(d){return $CLJS.R.j(d,$CLJS.KZ,!0)},$CLJS.H0.j(a,b,c)))},J9=function(a){return $CLJS.WY.h($CLJS.Qk.j($CLJS.Tz(a,
$CLJS.H([$CLJS.yi,!0])),0,$CLJS.zh))},Nwa=function(a){var b=$CLJS.Xa(a)?a:null;return $CLJS.n(b)?(a=$CLJS.z9.h?$CLJS.z9.h(b):$CLJS.z9.call(null,b),$CLJS.E.g($CLJS.PK.h(a),$CLJS.hL)?(b=J9(b.field_ref),$CLJS.R.j(a,$CLJS.a1,$CLJS.id(b))):a):a},K9=function(a,b){var c=null==a?null:$CLJS.A(a);c=null==c?null:$CLJS.E.g(c,$CLJS.YF);return $CLJS.n(c)?$CLJS.R.j(b,$CLJS.a1,$CLJS.id(a)):b},L9=function(a){return function(b){var c=function(){var e=a.h?a.h(b):a.call(null,b);return $CLJS.z9.h?$CLJS.z9.h(e):$CLJS.z9.call(null,
e)}(),d=function(){var e=$CLJS.J1.h(c);return $CLJS.n(e)?J9(e):null}();return new $CLJS.h(null,3,[$CLJS.Ti,K9(d,c),$CLJS.h6,d,$CLJS.Dj,b.value],null)}};$CLJS.g=C9.prototype;$CLJS.g.P=function(a,b){return new C9(this.fg,this.Ii,this.tc,b)};$CLJS.g.O=function(){return this.ji};$CLJS.g.ki=$CLJS.Bc;$CLJS.g.vg=function(a,b){a=this.tc;a=null==a?null:$CLJS.s9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.wg=function(a,b){return Jwa(this.tc,b)};
$CLJS.g.zg=function(a,b){a=this.tc;a=null==a?null:$CLJS.u9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.ug=function(a,b){return Iwa(this.tc,b)};$CLJS.g.tg=function(a,b){a=this.tc;a=null==a?null:$CLJS.SE.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.yg=function(a,b){return Kwa(this.tc,b)};
$CLJS.g.rg=function(a,b){a=this.tc;a=null==a?null:$CLJS.r9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.xg=function(a,b){a=this.tc;a=null==a?null:$CLJS.t9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};$CLJS.g.sg=function(){var a=this.tc,b=this.fg;a=null==a?null:$CLJS.w9.h(a);a=null==a?null:$CLJS.q(a);b=null==a?null:$CLJS.J.g(a,b);return null==b?null:$CLJS.q(b)};var M9=function M9(a){switch(arguments.length){case 1:return M9.h(arguments[0]);case 2:return M9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.extract_template_tags",M9);M9.h=function(a){return M9.g(a,$CLJS.N)};M9.g=function(a,b){return $CLJS.hl($CLJS.Dva.l($CLJS.H([a,D9(b)])))};M9.A=2;
var N9=function N9(a){return $CLJS.me(a)?[$CLJS.ie(a),"/",$CLJS.gh(a)].join(""):$CLJS.xd(a)?$CLJS.wt(a,N9):$CLJS.wd(a)?$CLJS.hf.g(N9,a):a},F9=$CLJS.VA(function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);c=$CLJS.I(c,1,null);d=$CLJS.WA(d);const e=d.length-1;d=0<=e&&d.indexOf("?",e)==e?["is-",$CLJS.KA(d,/\?$/,"")].join(""):d;d=$CLJS.FB.h?$CLJS.FB.h(d):$CLJS.FB.call(null,d);c=H9.h?H9.h(c):H9.call(null,c);b[d]=c;return b},{},a)}),G9=$CLJS.VA(function(a){return $CLJS.we($CLJS.hf.g(H9,a))}),
O9=function O9(a){switch(arguments.length){case 1:return O9.h(arguments[0]);case 2:return O9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.order_by_clause",O9);O9.h=function(a){return O9.g(a,$CLJS.fG)};O9.g=function(a,b){return $CLJS.Gva.l($CLJS.H([$CLJS.e9.l($CLJS.H([$CLJS.Tz(a,$CLJS.H([$CLJS.yi,!0]))])),$CLJS.zh.h(b)]))};O9.A=2;
var P9=function P9(a){switch(arguments.length){case 2:return P9.g(arguments[0],arguments[1]);case 3:return P9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_binning_strategies",P9);P9.g=function(a,b){return $CLJS.we($CLJS.d9.l($CLJS.H([a,b])))};P9.j=function(a,b,c){return $CLJS.we($CLJS.d9.l($CLJS.H([a,b,c])))};P9.A=3;
var Q9=function Q9(a){switch(arguments.length){case 2:return Q9.g(arguments[0],arguments[1]);case 3:return Q9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.available_temporal_buckets",Q9);Q9.g=function(a,b){return $CLJS.we($CLJS.g9.l($CLJS.H([a,b])))};Q9.j=function(a,b,c){return $CLJS.we($CLJS.g9.l($CLJS.H([a,b,c])))};Q9.A=3;
var R9=function R9(a){switch(arguments.length){case 2:return R9.g(arguments[0],arguments[1]);case 3:return R9.j(arguments[0],arguments[1],arguments[2]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.query_EQ_",R9);R9.g=function(a,b){return R9.j(a,b,null)};R9.j=function(a,b,c){a=I9(a,c);b=I9(b,c);return $CLJS.E.g(a,b)};R9.A=3;
var S9=function S9(a){switch(arguments.length){case 1:return S9.h(arguments[0]);case 2:return S9.g(arguments[0],arguments[1]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.aggregation_clause",S9);S9.h=function(a){return $CLJS.b9.l($CLJS.H([a]))};S9.g=function(a,b){return $CLJS.b9.l($CLJS.H([a,b]))};S9.A=2;
var T9=function T9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return T9.l(arguments[0],arguments[1],2<c.length?new $CLJS.w(c.slice(2),0,null):null)};$CLJS.za("metabase.lib.js.filter_clause",T9);T9.l=function(a,b,c){return $CLJS.Se.v($CLJS.Vua,a,b,c)};T9.A=2;T9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);c=$CLJS.B(c);return this.l(b,a,c)};
var U9=function U9(a){switch(arguments.length){case 3:return U9.j(arguments[0],arguments[1],arguments[2]);case 4:return U9.v(arguments[0],arguments[1],arguments[2],arguments[3]);default:throw Error(["Invalid arity: ",$CLJS.p.h(arguments.length)].join(""));}};$CLJS.za("metabase.lib.js.with_different_database",U9);U9.j=function(a,b,c){return U9.v(a,b,c,null)};U9.v=function(a,b,c,d){return $CLJS.Bva.l($CLJS.H([a,E9(b,c),$CLJS.Tz(d,$CLJS.H([$CLJS.yi,!0]))]))};U9.A=4;
var Owa=L9(function(a){return a.col}),Pwa=L9(function(a){return a.column}),V9=function V9(a){for(var c=[],d=arguments.length,e=0;;)if(e<d)c.push(arguments[e]),e+=1;else break;return V9.l(arguments[0],arguments[1],arguments[2],3<c.length?new $CLJS.w(c.slice(3),0,null):null)};$CLJS.za("metabase.lib.js.drill_thru",V9);V9.l=function(a,b,c,d){return $CLJS.Se.N($CLJS.wua,a,b,c,d)};V9.A=3;
V9.B=function(a){var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return this.l(b,a,c,d)};
module.exports={returned_columns:function(a,b){return $CLJS.X_($CLJS.zh.g("returned-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return Mwa(a,b)})},legacy_expression_for_expression_clause:function(a,b,c){b=$CLJS.c9.l($CLJS.H([a,b]));a=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);l=$CLJS.J.g(l,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,l],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(k,l){$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);l=$CLJS.O(l);
l=$CLJS.J.g(l,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[l,k],null)}),b);var d=$CLJS.VY,e=$CLJS.UY;$CLJS.VY=a;$CLJS.UY=b;try{var f=$CLJS.MY.h(c);return $CLJS.hl($CLJS.E.g($CLJS.A(f),$CLJS.tM)?$CLJS.J.g(f,1):f)}finally{$CLJS.UY=e,$CLJS.VY=d}},extract_template_tags:M9,engine:function(a){return $CLJS.gh($CLJS.wva.l($CLJS.H([a])))},field_id:function(a){return $CLJS.Kua.l($CLJS.H([a]))},filter_operator:function(a,b,c){return $CLJS.Wua.l($CLJS.H([a,b,c]))},binning:function(a){return $CLJS.mua.l($CLJS.H([a]))},
query:function(a,b,c){c=$CLJS.WY.h((0,$CLJS.IY)($CLJS.X1($CLJS.R7(c),$CLJS.mj,$CLJS.FF)));var d=$CLJS.FA($CLJS.uy);$CLJS.n($CLJS.EA("metabase.lib.js",d))&&$CLJS.OA("metabase.lib.js",d,$CLJS.rW("query map: %s",$CLJS.H([$CLJS.Mh.l($CLJS.H([c]))])),null);return $CLJS.Iva.l($CLJS.H([E9(a,b),c]))},columns_group_columns:function(a){return $CLJS.we($CLJS.rua.l($CLJS.H([a])))},join_fields:function(a){a=$CLJS.gva.l($CLJS.H([a]));return a instanceof $CLJS.M?$CLJS.WA(a):$CLJS.we(a)},aggregate:function(a,b,c){return $CLJS.iua.l($CLJS.H([a,
b,$CLJS.Tz(c,$CLJS.H([$CLJS.yi,!0]))]))},legacy_card_or_table_id:function(a){return $CLJS.Lua.l($CLJS.H([a]))},join_conditions:function(a){return $CLJS.we($CLJS.fva.l($CLJS.H([a])))},query_EQ_:R9,temporal_bucket:function(a){return $CLJS.Vva.l($CLJS.H([a]))},available_drill_thrus:function(a,b,c,d,e,f){var k=$CLJS.c9.l($CLJS.H([a,b])),l=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),
k);k=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),k);var m=$CLJS.VY,t=$CLJS.UY;$CLJS.VY=l;$CLJS.UY=k;try{var u=function(){var v=c.field_ref;return $CLJS.n(v)?J9(v):null}();return $CLJS.we($CLJS.vua.l($CLJS.H([a,b,$CLJS.ll.l($CLJS.H([new $CLJS.h(null,3,[$CLJS.Ti,K9(u,$CLJS.z9.h?$CLJS.z9.h(c):$CLJS.z9.call(null,c)),$CLJS.h6,u,$CLJS.Dj,void 0===d?null:null==d?$CLJS.N2:d],null),$CLJS.n(e)?
new $CLJS.h(null,1,[$CLJS.g6,$CLJS.Pk.g(Owa,e)],null):null,$CLJS.n($CLJS.Fe(f))?new $CLJS.h(null,1,[$CLJS.FL,$CLJS.Pk.g(Pwa,f)],null):null]))])))}finally{$CLJS.UY=t,$CLJS.VY=m}},remove_clause:function(a,b,c){return $CLJS.Lva.l($CLJS.H([a,b,$CLJS.e9.l($CLJS.H([$CLJS.Tz(c,$CLJS.H([$CLJS.yi,!0]))]))]))},replace_clause:function(a,b,c,d){return $CLJS.Ova.l($CLJS.H([a,b,$CLJS.e9.l($CLJS.H([$CLJS.Tz(c,$CLJS.H([$CLJS.yi,!0]))])),$CLJS.e9.l($CLJS.H([$CLJS.Tz(d,$CLJS.H([$CLJS.yi,!0]))]))]))},drop_stage:function(a){return $CLJS.Rva.l($CLJS.H([a]))},
join_lhs_display_name:function(a,b,c,d){return $CLJS.hva.l($CLJS.H([a,b,c,d]))},add_field:function(a,b,c){return $CLJS.Jua.l($CLJS.H([a,b,c]))},pivot_types:function(a){return $CLJS.we($CLJS.yua.l($CLJS.H([a])))},native_extras:function(a){return $CLJS.hl($CLJS.zva.l($CLJS.H([a])))},available_temporal_buckets:Q9,with_binning:function(a,b){return $CLJS.nua.l($CLJS.H([a,b]))},append_stage:function(a){return $CLJS.Qva.l($CLJS.H([a]))},join:function(a,b,c){return $CLJS.$ua.l($CLJS.H([a,b,c]))},aggregations:function(a,
b){return $CLJS.we($CLJS.c9.l($CLJS.H([a,b])))},drill_thru:V9,raw_native_query:function(a){return $CLJS.tva.l($CLJS.H([a]))},filterable_column_operators:function(a){return $CLJS.we($CLJS.Uua.l($CLJS.H([a])))},with_temporal_bucket:function(a,b){return $CLJS.Wva.l($CLJS.H([a,b]))},with_join_conditions:function(a,b){return $CLJS.ova.l($CLJS.H([a,$CLJS.Tz(b,$CLJS.H([$CLJS.yi,!0]))]))},available_metrics:function(a){return $CLJS.we($CLJS.rva.l($CLJS.H([a])))},with_different_database:U9,with_fields:function(a,
b,c){return $CLJS.Qua.l($CLJS.H([a,b,c]))},is_column_metadata:function(a){return $CLJS.xd(a)&&$CLJS.E.g($CLJS.NK,$CLJS.Vt.h(a))},filter_args_display_name:function(a,b,c){return $CLJS.Iua.l($CLJS.H([a,b,c]))},join_clause:function(a,b){return $CLJS.ava.l($CLJS.H([a,b]))},aggregation_clause:S9,external_op:function(a){a=$CLJS.tua.l($CLJS.H([a]));var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.vE);var c=$CLJS.J.g(b,$CLJS.fj);b=$CLJS.J.g(b,$CLJS.Vs);return{operator:a,options:$CLJS.hl(c),args:$CLJS.we(b)}},with_join_fields:function(a,
b){return $CLJS.mva.l($CLJS.H([a,"string"===typeof b?$CLJS.zh.h(b):b]))},find_visible_column_for_legacy_ref:function(a,b,c){return $CLJS.Oua.l($CLJS.H([a,b,c]))},visible_columns:function(a,b){return $CLJS.X_($CLJS.zh.g("visible-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){var c=$CLJS.CW(a,b),d=$CLJS.J0.j(a,b,c);c=$CLJS.H0.j(a,b,c);return $CLJS.we($CLJS.x3(a,b,d,c))})},available_binning_strategies:P9,find_column_indexes_from_legacy_refs:function(a,b,c,d){var e=$CLJS.YF.h($CLJS.CW(a,b)),
f=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[u,v],null)}),e);e=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(u,v){$CLJS.I(v,0,null);v=$CLJS.I(v,1,null);v=$CLJS.O(v);v=$CLJS.J.g(v,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,u],null)}),e);var k=$CLJS.VY,l=$CLJS.UY;$CLJS.VY=f;$CLJS.UY=e;try{var m=$CLJS.Pk.g(Nwa,c),t=$CLJS.hf.g(J9,d);return $CLJS.we(Hwa(a,b,t,m))}finally{$CLJS.UY=l,$CLJS.VY=k}},
expression:function(a,b,c,d){return $CLJS.Bua.l($CLJS.H([a,b,c,d]))},describe_relative_datetime:function(a,b){a="string"===typeof a?$CLJS.zh.h(a):a;b="string"===typeof b?$CLJS.zh.h(b):b;return $CLJS.Uva.l($CLJS.H([a,b]))},order_bys:function(a,b){return $CLJS.we($CLJS.Hva.l($CLJS.H([a,b])))},display_info:function(a,b,c){return $CLJS.X_($CLJS.zh.g("display-info-outer",["stage-",$CLJS.p.h(b)].join("")),c,function(d){return H9($CLJS.pva.l($CLJS.H([$CLJS.Q3(a,b),b,d])))})},fieldable_columns:function(a,
b){return $CLJS.X_($CLJS.zh.g("fieldable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.Mua.l($CLJS.H([a,b])))})},template_tags:function(a){return $CLJS.hl($CLJS.vva.l($CLJS.H([a])))},selected_aggregation_operators:function(a,b){return $CLJS.we($CLJS.lua.l($CLJS.H([$CLJS.y(a),b])))},order_by_clause:O9,filterable_columns:function(a,b){return $CLJS.X_($CLJS.zh.g("filterable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.Tua.l($CLJS.H([a,
b])))})},join_condition_rhs_columns:function(a,b,c,d,e){return $CLJS.we($CLJS.dva.l($CLJS.H([a,b,c,d,e])))},join_condition_update_temporal_bucketing:function(a,b,c,d){return $CLJS.eva.l($CLJS.H([a,b,c,d]))},filters:function(a,b){return $CLJS.we($CLJS.Sua.l($CLJS.H([a,b])))},join_condition_lhs_columns:function(a,b,c,d,e){return $CLJS.we($CLJS.bva.l($CLJS.H([a,b,c,d,e])))},table_or_card_metadata:function(a,b){return $CLJS.A1(a,b)},with_expression_name:function(a,b){return $CLJS.Fua.l($CLJS.H([a,b]))},
orderable_columns:function(a,b){return $CLJS.X_($CLJS.zh.g("orderable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.N7.g(a,b))})},find_filter_for_legacy_filter:function(a,b,c){return $CLJS.Xua.l($CLJS.H([a,b,$CLJS.Tz(c,$CLJS.H([$CLJS.yi,!0]))]))},suggestedName:function(a){return $CLJS.qva.l($CLJS.H([a]))},find_column_for_legacy_ref:function(a,b,c,d){return $CLJS.zua.l($CLJS.H([a,b,c,d]))},has_write_permission:function(a){return $CLJS.Cva.l($CLJS.H([a]))},metadataProvider:E9,
filter_clause:T9,fields:function(a,b){return $CLJS.we($CLJS.Nua.l($CLJS.H([a,b])))},available_aggregation_operators:function(a,b){return $CLJS.we($CLJS.kua.l($CLJS.H([a,b])))},expression_name:function(a){return $CLJS.Cua.l($CLJS.H([a]))},breakouts:function(a,b){return $CLJS.we($CLJS.qua.l($CLJS.H([a,b])))},joinable_columns:function(a,b,c){return $CLJS.we($CLJS.jva.l($CLJS.H([a,b,c])))},group_columns:function(a){return $CLJS.we($CLJS.sua.l($CLJS.H([a])))},describe_temporal_unit:function(a,b){b="string"===
typeof b?$CLJS.zh.h(b):b;return $CLJS.Sva.l($CLJS.H([a,b]))},joins:function(a,b){return $CLJS.we($CLJS.kva.l($CLJS.H([a,b])))},native_query:function(a,b,c){return $CLJS.sva.l($CLJS.H([E9(a,b),c]))},available_join_strategies:function(a,b){return $CLJS.we($CLJS.Zua.l($CLJS.H([a,b])))},legacy_query:function(a){return $CLJS.hl.l(N9($CLJS.MY.h(a)),$CLJS.H([$CLJS.wk,$CLJS.WA]))},stage_count:function(a){return $CLJS.Jva.l($CLJS.H([a]))},expression_clause:function(a,b,c){return $CLJS.Gua.l($CLJS.H([$CLJS.zh.h(a),
b,$CLJS.Tz(c,$CLJS.H([$CLJS.yi,!0]))]))},find_matching_column:function(a,b,c,d){return $CLJS.Aua.l($CLJS.H([a,b,c,d]))},with_native_extras:function(a,b){return $CLJS.Ava.l($CLJS.H([a,$CLJS.Tz(b,$CLJS.H([$CLJS.yi,!0]))]))},join_strategy:function(a){return $CLJS.iva.l($CLJS.H([a]))},picker_info:function(a,b){var c=$CLJS.Vt.h(b);switch(c instanceof $CLJS.M?c.T:null){case "metadata/table":return{databaseId:$CLJS.TP.h(a),tableId:$CLJS.Zi.h(b)};case "metadata/card":return{databaseId:$CLJS.TP.h(a),tableId:["card__",
$CLJS.p.h($CLJS.Zi.h(b))].join(""),cardId:$CLJS.Zi.h(b),isModel:$CLJS.AZ.h(b)};default:return a=$CLJS.FA($CLJS.Dy),$CLJS.n($CLJS.EA("metabase.lib.js",a))&&$CLJS.OA("metabase.lib.js",a,$CLJS.zw.l($CLJS.H(["Cannot provide picker-info for",$CLJS.Vt.h(b)])),null),null}},expression_parts:function(a,b,c){a=$CLJS.Hua.l($CLJS.H([a,b,c]));return $CLJS.BV(function(d){if($CLJS.xd(d)&&$CLJS.E.g($CLJS.E8,$CLJS.Vt.h(d))){var e=$CLJS.O(d);d=$CLJS.J.g(e,$CLJS.vE);var f=$CLJS.J.g(e,$CLJS.fj);e=$CLJS.J.g(e,$CLJS.Vs);
return{operator:$CLJS.gh(d),options:$CLJS.hl($CLJS.cm(f,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.qG,$CLJS.gk],null))),args:$CLJS.we($CLJS.hf.g(function(k){return k instanceof $CLJS.M?$CLJS.WA(k):k},e))}}return d},a)},expression_clause_for_legacy_expression:function(a,b,c){b=$CLJS.c9.l($CLJS.H([a,b]));a=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(l,m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[l,m],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(l,
m){$CLJS.I(m,0,null);m=$CLJS.I(m,1,null);m=$CLJS.O(m);m=$CLJS.J.g(m,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[m,l],null)}),b);var d=$CLJS.VY,e=$CLJS.UY;$CLJS.VY=a;$CLJS.UY=b;try{var f=$CLJS.Tz(c,$CLJS.H([$CLJS.yi,!0])),k=$CLJS.A($CLJS.yY(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.FF,$CLJS.YF],null),new $CLJS.P(null,1,5,$CLJS.Q,[f],null)));return $CLJS.WY.h(k)}finally{$CLJS.UY=e,$CLJS.VY=d}},format_relative_date_range:function(a,b,c,d,e){b=$CLJS.zh.h(b);d=null==d?null:$CLJS.zh.h(d);e=$CLJS.Tz(e,$CLJS.H([$CLJS.yi,
!0]));return $CLJS.A2((0,$CLJS.MI)(),a,b,c,d,e)},aggregation_operator_columns:function(a){return $CLJS.we($CLJS.jua.l($CLJS.H([a])))},find_filterable_column_for_legacy_ref:function(a,b,c){return $CLJS.Yua.l($CLJS.H([a,b,c]))},available_segments:function(a,b){return $CLJS.we($CLJS.Pva.l($CLJS.H([a,b])))},join_condition_operators:function(a,b,c,d){return $CLJS.we($CLJS.cva.l($CLJS.H([a,b,c,d])))},expressionable_columns:function(a,b,c){return $CLJS.X_($CLJS.zh.g("expressionable-columns",["stage-",$CLJS.p.h(b),
"-",$CLJS.p.h(c)].join("")),a,function(){return $CLJS.we($CLJS.Eua.l($CLJS.H([a,b,c])))})},filter:function(a,b,c){return $CLJS.Rua.l($CLJS.H([a,b,$CLJS.Tz(c,$CLJS.H([$CLJS.yi,!0]))]))},change_direction:function(a,b){return $CLJS.Eva.l($CLJS.H([a,b]))},pivot_columns_for_type:function(a,b){return $CLJS.xua.l($CLJS.H([a,b]))},filter_drill_details:function(a){var b=$CLJS.O(a);a=$CLJS.J.g(b,$CLJS.FF);var c=$CLJS.J.g(b,$CLJS.Ti),d=$CLJS.J.g(b,$CLJS.CL);b=$CLJS.J.g(b,$CLJS.Dj);return{column:c,query:a,stageNumber:d,
value:$CLJS.E.g(b,$CLJS.N2)?null:b}},legacy_field_ref:function(a){return $CLJS.hl($CLJS.Qk.v($CLJS.MY.h($CLJS.f9.l($CLJS.H([a]))),2,$CLJS.wt,function(b){return $CLJS.me(b)?$CLJS.WA(b):b}))},remove_field:function(a,b,c){return $CLJS.Pua.l($CLJS.H([a,b,c]))},with_template_tags:function(a,b){return $CLJS.xva.l($CLJS.H([a,D9(b)]))},expressions:function(a,b){return $CLJS.we($CLJS.Dua.l($CLJS.H([a,b])))},suggested_join_conditions:function(a,b,c){return $CLJS.we($CLJS.lva.l($CLJS.H([a,b,c])))},with_join_strategy:function(a,
b){return $CLJS.nva.l($CLJS.H([a,b]))},order_by:function(a,b,c,d){return $CLJS.Fva.l($CLJS.H([a,b,c,$CLJS.zh.h(d)]))},describe_temporal_interval:function(a,b){a="string"===typeof a?$CLJS.zh.h(a):a;b="string"===typeof b?$CLJS.zh.h(b):b;return $CLJS.Tva.l($CLJS.H([a,b]))},breakout:function(a,b,c){return $CLJS.oua.l($CLJS.H([a,b,$CLJS.f9.l($CLJS.H([c]))]))},database_id:function(a){return $CLJS.uua.l($CLJS.H([a]))},required_native_extras:function(a,b){return $CLJS.we($CLJS.hf.g($CLJS.WA,$CLJS.yva.l($CLJS.H([E9(a,
b)]))))},remove_join:function(a,b,c){return $CLJS.Mva.l($CLJS.H([a,b,c]))},joined_thing:function(a,b){return $CLJS.K3(a,b)},with_native_query:function(a,b){return $CLJS.uva.l($CLJS.H([a,b]))},breakoutable_columns:function(a,b){return $CLJS.X_($CLJS.zh.g("breakoutable-columns",["stage-",$CLJS.p.h(b)].join("")),a,function(){return $CLJS.we($CLJS.pua.l($CLJS.H([a,b])))})},rename_join:function(a,b,c,d){return $CLJS.Nva.l($CLJS.H([a,b,c,d]))},with_different_table:function(a,b){return $CLJS.Kva.l($CLJS.H([a,
b]))}};