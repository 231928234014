var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.schema.common.js");require("./metabase.shared.util.i18n.js");require("./metabase.types.js");require("./metabase.util.malli.js");require("./metabase.util.malli.registry.js");
'use strict';var KE,PE,dfa,efa,ffa,gfa,hfa,ifa;
$CLJS.JE=function(){function a(){return Math.floor(16*Math.random()).toString(16)}var b=(8|3&Math.floor(16*Math.random())).toString(16);return new $CLJS.nA([$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-4",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(b),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),"-",$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),
$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a()),$CLJS.p.h(a())].join("").toLowerCase())};$CLJS.QE=function(a){for(var b=[],c=arguments.length,d=0;;)if(d<c)b.push(arguments[d]),d+=1;else break;return $CLJS.oE(arguments[0],1<b.length?new $CLJS.w(b.slice(1),0,null):null)};KE=function(a){return $CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M};
$CLJS.ME=function(a){var b=function(){var c=KE(a);return c?(c=$CLJS.xd($CLJS.hd(a)))?(c=$CLJS.Ei.h($CLJS.hd(a)),$CLJS.n(c)?c:$CLJS.pB.h($CLJS.hd(a))):c:c}();return $CLJS.n(b)?b:$CLJS.LE.h(a)};
PE=function(a,b){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,2,[$CLJS.pt,"valid MBQL clause",$CLJS.rt,function(c){c=$CLJS.O(c);c=$CLJS.J.g(c,$CLJS.Dj);return["invalid MBQL clause: ",$CLJS.Mh.l($CLJS.H([c]))].join("")}],null),$CLJS.Te(KE)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.NE],null)],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ri,new $CLJS.h(null,1,[$CLJS.pt,b],null),function(c){c=$CLJS.ME(c);
return OE(c,a)}],null)],null)};$CLJS.RE=new $CLJS.M(null,"right-join","right-join",-56349359);dfa=new $CLJS.M("metabase.lib.schema.expression","datetime","metabase.lib.schema.expression/datetime",-51960022);efa=new $CLJS.M("metabase.lib.schema.expression","base-type","metabase.lib.schema.expression/base-type",578343967);ffa=new $CLJS.M("metabase.lib.schema.expression","non-integer-real","metabase.lib.schema.expression/non-integer-real",-1855491016);$CLJS.SE=new $CLJS.M(null,"fields","fields",-1932066230);
$CLJS.TE=new $CLJS.M(null,"expr","expr",745722291);$CLJS.UE=new $CLJS.M("metabase.lib.schema.expression","expressions","metabase.lib.schema.expression/expressions",790922225);$CLJS.VE=new $CLJS.M("metabase.lib.schema.expression","temporal","metabase.lib.schema.expression/temporal",320116754);$CLJS.WE=new $CLJS.M(null,"alias","alias",-2039751630);$CLJS.XE=new $CLJS.M("metabase.lib.schema.expression","boolean","metabase.lib.schema.expression/boolean",-1396506592);
$CLJS.YE=new $CLJS.M("metabase.lib.schema.expression","expression","metabase.lib.schema.expression/expression",-1389098704);$CLJS.ZE=new $CLJS.M("metabase.lib.schema.expression","type.unknown","metabase.lib.schema.expression/type.unknown",-98159107);$CLJS.$E=new $CLJS.M("mbql.clause","field","mbql.clause/field",1497292735);gfa=new $CLJS.M("metabase.lib.schema.expression","date","metabase.lib.schema.expression/date",1943847782);$CLJS.aF=new $CLJS.M(null,"left-join","left-join",-672831855);
$CLJS.bF=new $CLJS.M("metabase.lib.schema.expression","equality-comparable","metabase.lib.schema.expression/equality-comparable",-658449046);$CLJS.cF=new $CLJS.M("lib.type-of","type-is-type-of-first-arg","lib.type-of/type-is-type-of-first-arg",-317600808);$CLJS.dF=new $CLJS.M("metabase.lib.schema.expression","orderable","metabase.lib.schema.expression/orderable",-1555566130);hfa=new $CLJS.r("metabase.lib.schema.expression","type-of","metabase.lib.schema.expression/type-of",-1625245814,null);
ifa=new $CLJS.M("metabase.lib.schema.expression","time","metabase.lib.schema.expression/time",865466446);$CLJS.eF=new $CLJS.M(null,"inner-join","inner-join",659431740);$CLJS.fF=new $CLJS.M("metabase.lib.schema.expression","emptyable","metabase.lib.schema.expression/emptyable",941775581);$CLJS.NE=new $CLJS.M("metabase.lib.schema.mbql-clause","clause","metabase.lib.schema.mbql-clause/clause",955279388);
$CLJS.gF=new $CLJS.M("metabase.lib.schema.expression","string","metabase.lib.schema.expression/string",1750008170);$CLJS.hF=new $CLJS.M(null,"full-join","full-join",1305476385);$CLJS.iF=new $CLJS.M("metabase.lib.schema.expression","number","metabase.lib.schema.expression/number",779948930);$CLJS.jF=new $CLJS.M("metabase.lib.schema.expression","integer","metabase.lib.schema.expression/integer",2060430870);$CLJS.LE=function(){var a=$CLJS.Xe($CLJS.N),b=$CLJS.Xe($CLJS.N),c=$CLJS.Xe($CLJS.N),d=$CLJS.Xe($CLJS.N),e=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.fC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));return new $CLJS.di($CLJS.Fh.g("metabase.lib.schema.expression","type-of-method"),function(f){var k=$CLJS.dC(f);return $CLJS.E.g(k,$CLJS.oB)?$CLJS.ab(f):k},e,a,b,c,d)}();
$CLJS.X(efa,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.ZE],null),$CLJS.BE],null));$CLJS.LE.m(null,$CLJS.ci,function(a){throw $CLJS.hi($CLJS.oE("{0}: Don''t know how to determine the type of {1}",$CLJS.H([hfa,$CLJS.Mh.l($CLJS.H([a]))])),new $CLJS.h(null,1,[$CLJS.TE,a],null));});$CLJS.LE.m(null,$CLJS.cF,function(a){$CLJS.I(a,0,null);$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.ME(a)});
var OE=function OE(a,b){return $CLJS.vd(a)?$CLJS.Re(function(d){return OE.g?OE.g(d,b):OE.call(null,d,b)},a):$CLJS.vd(b)?$CLJS.Re(function(d){return OE.g?OE.g(a,d):OE.call(null,a,d)},b):$CLJS.E.g(a,$CLJS.ZE)?!0:$CLJS.yC(a,b)};$CLJS.X($CLJS.XE,PE($CLJS.Lj,"expression returning a boolean"));$CLJS.X($CLJS.gF,PE($CLJS.dk,"expression returning a string"));$CLJS.X($CLJS.jF,PE($CLJS.wj,"expression returning an integer"));$CLJS.X(ffa,PE($CLJS.OD,"expression returning a non-integer real number"));
$CLJS.X($CLJS.iF,PE($CLJS.Pj,"expression returning a number"));$CLJS.X(gfa,PE($CLJS.SC,"expression returning a date"));$CLJS.X(ifa,PE($CLJS.RD,"expression returning a time"));$CLJS.X(dfa,PE($CLJS.rD,"expression returning a date time"));$CLJS.X($CLJS.VE,PE($CLJS.Wj,"expression returning a date, time, or date time"));$CLJS.kF=new $CLJS.ah(null,new $CLJS.h(null,4,[$CLJS.Pj,null,$CLJS.dk,null,$CLJS.Wj,null,$CLJS.Lj,null],null),null);$CLJS.X($CLJS.dF,PE($CLJS.kF,"an expression that can be compared with :\x3e or :\x3c"));
var jfa=new $CLJS.ah(null,new $CLJS.h(null,8,[$CLJS.pC,null,$CLJS.Pj,null,$CLJS.dk,null,$CLJS.hD,null,$CLJS.Wj,null,$CLJS.Aj,null,$CLJS.Lj,null,$CLJS.MD,null],null),null);$CLJS.X($CLJS.fF,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null),PE($CLJS.hD,"expression returning a BSONID")],null));$CLJS.X($CLJS.bF,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,PE(jfa,"an expression that can appear in :\x3d or :!\x3d")],null));
$CLJS.X($CLJS.YE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.os,PE($CLJS.Aj,"any type of expression")],null));
$CLJS.X($CLJS.UE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.zj,new $CLJS.h(null,1,[$CLJS.$n,1],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ws,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null),new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Fj,$CLJS.Gs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rE,$CLJS.Zj],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Xr,$CLJS.Gs],null)],null)],null)],null));