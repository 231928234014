var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./lambdaisland.glogi.js");require("./lambdaisland.glogi.print.js");
'use strict';var Kz=function(){},Lz=function(a){return $CLJS.J.g($CLJS.zy,a).value},yda=function(a){var b=Lz(a);return $CLJS.n(function(){var c=Lz($CLJS.Iy);return $CLJS.Ik.g?$CLJS.Ik.g(c,b):$CLJS.Ik.call(null,c,b)}())?$CLJS.nz:$CLJS.n(function(){var c=Lz($CLJS.Hy);return $CLJS.Ik.g?$CLJS.Ik.g(c,b):$CLJS.Ik.call(null,c,b)}())?$CLJS.xz:$CLJS.n(function(){var c=Lz($CLJS.Fy);return $CLJS.Ik.g?$CLJS.Ik.g(c,b):$CLJS.Ik.call(null,c,b)}())?$CLJS.qz:$CLJS.n(function(){var c=Lz($CLJS.sy);return $CLJS.Ik.g?
$CLJS.Ik.g(c,b):$CLJS.Ik.call(null,c,b)}())?$CLJS.wz:$CLJS.n(function(){var c=Lz($CLJS.Gy);return $CLJS.Ik.g?$CLJS.Ik.g(c,b):$CLJS.Ik.call(null,c,b)}())?$CLJS.yz:$CLJS.n(function(){var c=Lz($CLJS.yy);return $CLJS.Ik.g?$CLJS.Ik.g(c,b):$CLJS.Ik.call(null,c,b)}())?$CLJS.tz:$CLJS.n(function(){var c=Lz($CLJS.py);return $CLJS.Ik.g?$CLJS.Ik.g(c,b):$CLJS.Ik.call(null,c,b)}())?$CLJS.uz:$CLJS.vz},Mz=function(a){var b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(b),$CLJS.p.h(" ")].join(""),
a],null)},Nz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Jz,c))].join(""),$CLJS.H(["color:black"]))],null)},Oz=function(a,b,c){var d=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[[$CLJS.p.h(d),"%c",$CLJS.p.h(b),"%c"].join(""),$CLJS.be.l(a,["color:",$CLJS.p.h($CLJS.J.g($CLJS.Jz,$CLJS.zz)),";background-color:",$CLJS.p.h($CLJS.J.g($CLJS.Jz,
c))].join(""),$CLJS.H(["color:black;background-color:inherit"]))],null)},zda=function(a){function b(c,d){return d>=c}a=Lz(a);if(b(Lz($CLJS.Iy),a))return"error";if(b(Lz($CLJS.Hy),a))return"warn";if(b(Lz($CLJS.Fy),a))return"info";b(Lz($CLJS.sy),a);return"log"},Pz=function(a){return function(b){var c=$CLJS.O(b),d=$CLJS.J.g(c,$CLJS.qy),e=$CLJS.J.g(c,$CLJS.Et);b=$CLJS.J.g(c,$CLJS.Jy);e=zda(e);e=$CLJS.Ca(console,e);e=$CLJS.n(e)?e:console.log;$CLJS.Se.g(e,a.h?a.h(c):a.call(null,c));return $CLJS.n(b)?(c=
["[",$CLJS.p.h(d),"]"].join(""),d=$CLJS.p.h(b),b=b.stack,e.v?e.v(c,d,"\n",b):e.call(null,c,d,"\n",b)):null}},Qz=function Qz(a,b){for(;;){if($CLJS.E.g($CLJS.mz,b))return Nz(a,", ",$CLJS.vz);if($CLJS.E.g($CLJS.oz,b))return Mz(a);if(b instanceof $CLJS.M)return Nz(a,b,$CLJS.qz);if(b instanceof $CLJS.r)return Nz(a,b,$CLJS.wz);if("string"===typeof b)return Nz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.pz);if($CLJS.zf(b)){var d=Mz(function(){var f=a,k=$CLJS.Eb(b);return Qz.g?Qz.g(f,k):Qz.call(null,f,k)}()),e=$CLJS.Fb(b);
return Qz.g?Qz.g(d,e):Qz.call(null,d,e)}if(b instanceof $CLJS.h||b instanceof $CLJS.Hg)return d=a,d=Nz(d,"{",$CLJS.Az),d=$CLJS.fb(Qz,d,$CLJS.ff($CLJS.mz,b)),Nz(d,"}",$CLJS.Az);if($CLJS.xd(b))return d=a,d=Nz(d,["#",$CLJS.p.h(function(){var f=$CLJS.ab(b),k=f.name;return $CLJS.td(k)?$CLJS.Mh.l($CLJS.H([f])):k}())," "].join(""),$CLJS.rz),d=Nz(d,"{",$CLJS.Az),d=$CLJS.fb(Qz,d,$CLJS.ff($CLJS.mz,b)),Nz(d,"}",$CLJS.Az);if($CLJS.vd(b))return d=a,d=Nz(d,"#{",$CLJS.Az),d=$CLJS.fb(Qz,d,$CLJS.ff($CLJS.oz,b)),Nz(d,
"}",$CLJS.Az);if($CLJS.zd(b))return d=a,d=Nz(d,"[",$CLJS.Az),d=$CLJS.fb(Qz,d,$CLJS.ff($CLJS.oz,b)),Nz(d,"]",$CLJS.Az);if(b instanceof $CLJS.Rf)d=Nz(a,"#queue ",$CLJS.rz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else{if($CLJS.Dd(b))return d=a,d=Nz(d,"(",$CLJS.rz),d=$CLJS.fb(Qz,d,$CLJS.ff($CLJS.oz,b)),Nz(d,")",$CLJS.rz);if(null!=b?b.I&16384||$CLJS.Bc===b.$i||(b.I?0:$CLJS.$a(Kz,b)):$CLJS.$a(Kz,b))d=Nz(a,"#atom ",$CLJS.rz),e=$CLJS.q(b),a=d,b=e;else if($CLJS.ei(b))d=Nz(a,"#uuid ",$CLJS.rz),e=$CLJS.p.h(b),a=d,
b=e;else if($CLJS.Xa(b))d=Nz(a,"#js ",$CLJS.rz),e=$CLJS.fb(function(f,k){return function(l,m){return $CLJS.R.j(l,$CLJS.zh.h(m),$CLJS.Ca(k,m))}}(a,b),$CLJS.N,Object.keys(b)),a=d,b=e;else if($CLJS.Ua(b))d=Nz(a,"#js ",$CLJS.rz),e=$CLJS.eg.g($CLJS.Cf,b),a=d,b=e;else return Nz(a,$CLJS.Mh.l($CLJS.H([b])),$CLJS.sz)}}};$CLJS.Ada=Pz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Et);var b=$CLJS.J.g(a,$CLJS.qy),c=$CLJS.J.g(a,$CLJS.Qi);$CLJS.J.g(a,$CLJS.Jy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),c],null)});
$CLJS.Bda=Pz(function(a){var b=$CLJS.O(a),c=$CLJS.J.g(b,$CLJS.Et);a=$CLJS.J.g(b,$CLJS.qy);var d=$CLJS.J.g(b,$CLJS.Qi);$CLJS.J.g(b,$CLJS.Jy);b=yda(c);d=Qz(Mz(Oz(Oz(Oz(new $CLJS.P(null,2,5,$CLJS.Q,["",$CLJS.Cf],null),"[",b),a,b),"]",b)),d);a=$CLJS.I(d,0,null);d=$CLJS.I(d,1,null);return $CLJS.ee(a,d)});
$CLJS.Cda=Pz(function(a){a=$CLJS.O(a);$CLJS.J.g(a,$CLJS.Et);var b=$CLJS.J.g(a,$CLJS.qy),c=$CLJS.J.g(a,$CLJS.Qi);$CLJS.J.g(a,$CLJS.Jy);return new $CLJS.P(null,2,5,$CLJS.Q,[["[",$CLJS.p.h(b),"]"].join(""),$CLJS.Mh.l($CLJS.H([c]))],null)});