var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.filter.js");require("./metabase.lib.types.isa.js");require("./metabase.shared.util.i18n.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var k2=function(a,b){a=a instanceof $CLJS.M?a.T:null;switch(a){case "\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "equal-to":return $CLJS.QE("Equal to");case "default":return $CLJS.QE("Is");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "!\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-equal-to":return $CLJS.QE("Not equal to");case "excludes":return $CLJS.QE("Excludes");case "default":return $CLJS.QE("Is not");default:throw Error(["No matching clause: ",
$CLJS.p.h(b)].join(""));}case "\x3e":switch(b=b instanceof $CLJS.M?b.T:null,b){case "after":return $CLJS.QE("After");case "default":return $CLJS.QE("Greater than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c":switch(b=b instanceof $CLJS.M?b.T:null,b){case "before":return $CLJS.QE("Before");case "default":return $CLJS.QE("Less than");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3e\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Greater than or equal to");
default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "\x3c\x3d":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Less than or equal to");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "between":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Between");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "is-empty":return $CLJS.QE("Is empty");
case "default":return $CLJS.QE("Is null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-null":switch(b=b instanceof $CLJS.M?b.T:null,b){case "not-empty":return $CLJS.QE("Not empty");case "default":return $CLJS.QE("Not null");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "is-empty":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Is empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "not-empty":switch(b=
b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Not empty");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "contains":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Contains");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "does-not-contain":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Does not contain");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}case "starts-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Starts with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "ends-with":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Ends with");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));}case "inside":switch(b=b instanceof $CLJS.M?b.T:null,b){case "default":return $CLJS.QE("Inside");default:throw Error(["No matching clause: ",$CLJS.p.h(b)].join(""));
}default:throw Error(["No matching clause: ",$CLJS.p.h(a)].join(""));}},l2=function(a,b){switch(a instanceof $CLJS.M?a.T:null){case "\x3d":return"\x3d";case "!\x3d":return"≠";case "\x3e":return"\x3e";case "\x3c":return"\x3c";case "\x3e\x3d":return"≥";case "\x3c\x3d":return"≤";default:return k2(a,b)}},uoa=new $CLJS.M(null,"after","after",594996914),voa=new $CLJS.M(null,"equal-to","equal-to",608296653),woa=new $CLJS.M(null,"excludes","excludes",-1791725945),xoa=new $CLJS.M(null,"not-equal-to","not-equal-to",
-343181655),yoa=new $CLJS.M(null,"before","before",-1633692388);$CLJS.m2=function(){function a(d,e){return new $CLJS.h(null,3,[$CLJS.Vt,$CLJS.SJ,$CLJS.bG,d,$CLJS.TJ,e],null)}function b(d){return c.g?c.g(d,$CLJS.ci):c.call(null,d,$CLJS.ci)}var c=null;c=function(d,e){switch(arguments.length){case 1:return b.call(this,d);case 2:return a.call(this,d,e)}throw Error("Invalid arity: "+arguments.length);};c.h=b;c.g=a;return c}();
$CLJS.n2=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.m2.h($CLJS.tk),$CLJS.m2.h($CLJS.zG),$CLJS.m2.h($CLJS.Or),$CLJS.m2.h($CLJS.Sr),$CLJS.m2.h($CLJS.LF),$CLJS.m2.h($CLJS.Qr),$CLJS.m2.h($CLJS.Ur),$CLJS.m2.g($CLJS.KF,$CLJS.MF),$CLJS.m2.g($CLJS.VF,$CLJS.TF)],null);$CLJS.zoa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.m2.h($CLJS.tk),$CLJS.m2.h($CLJS.zG),$CLJS.m2.h($CLJS.MF),$CLJS.m2.h($CLJS.TF),$CLJS.m2.h($CLJS.hG),$CLJS.m2.h($CLJS.aG),$CLJS.m2.h($CLJS.$F),$CLJS.m2.h($CLJS.tG)],null);
$CLJS.Aoa=new $CLJS.P(null,7,5,$CLJS.Q,[$CLJS.m2.g($CLJS.zG,woa),$CLJS.m2.h($CLJS.tk),$CLJS.m2.g($CLJS.Sr,yoa),$CLJS.m2.g($CLJS.Or,uoa),$CLJS.m2.h($CLJS.LF),$CLJS.m2.g($CLJS.KF,$CLJS.MF),$CLJS.m2.g($CLJS.VF,$CLJS.TF)],null);$CLJS.Boa=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.m2.h($CLJS.tk),$CLJS.m2.h($CLJS.zG),$CLJS.m2.h($CLJS.RF),$CLJS.m2.h($CLJS.Or),$CLJS.m2.h($CLJS.Sr),$CLJS.m2.h($CLJS.LF),$CLJS.m2.h($CLJS.Qr),$CLJS.m2.h($CLJS.Ur)],null);
$CLJS.Coa=new $CLJS.P(null,9,5,$CLJS.Q,[$CLJS.m2.g($CLJS.tk,voa),$CLJS.m2.g($CLJS.zG,xoa),$CLJS.m2.h($CLJS.Or),$CLJS.m2.h($CLJS.Sr),$CLJS.m2.h($CLJS.LF),$CLJS.m2.h($CLJS.Qr),$CLJS.m2.h($CLJS.Ur),$CLJS.m2.g($CLJS.KF,$CLJS.MF),$CLJS.m2.g($CLJS.VF,$CLJS.TF)],null);
$CLJS.Doa=new $CLJS.P(null,10,5,$CLJS.Q,[$CLJS.m2.h($CLJS.tk),$CLJS.m2.h($CLJS.zG),$CLJS.m2.h($CLJS.hG),$CLJS.m2.h($CLJS.aG),$CLJS.m2.h($CLJS.KF),$CLJS.m2.h($CLJS.VF),$CLJS.m2.h($CLJS.MF),$CLJS.m2.h($CLJS.TF),$CLJS.m2.h($CLJS.$F),$CLJS.m2.h($CLJS.tG)],null);$CLJS.Eoa=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.m2.h($CLJS.tk),$CLJS.m2.h($CLJS.zG),$CLJS.m2.h($CLJS.KF),$CLJS.m2.h($CLJS.VF),$CLJS.m2.h($CLJS.MF),$CLJS.m2.h($CLJS.TF)],null);
$CLJS.Foa=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.m2.h($CLJS.tk),$CLJS.m2.g($CLJS.KF,$CLJS.MF),$CLJS.m2.g($CLJS.VF,$CLJS.TF)],null);$CLJS.Goa=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.m2.h($CLJS.tk),$CLJS.m2.h($CLJS.zG),$CLJS.m2.h($CLJS.KF),$CLJS.m2.h($CLJS.VF)],null);$CLJS.o2=new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.R.j($CLJS.m2.h($CLJS.tk),$CLJS.ci,!0),$CLJS.m2.h($CLJS.Or),$CLJS.m2.h($CLJS.Sr),$CLJS.m2.h($CLJS.Qr),$CLJS.m2.h($CLJS.Ur),$CLJS.m2.h($CLJS.zG)],null);
$CLJS.y0.m(null,$CLJS.SJ,function(a,b,c,d){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.bG);b=$CLJS.J.g(b,$CLJS.TJ);d=d instanceof $CLJS.M?d.T:null;switch(d){case "default":return l2(a,b);case "long":return k2(a,b);default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}});
$CLJS.F0.m(null,$CLJS.SJ,function(a,b,c){c=$CLJS.O(c);a=$CLJS.J.g(c,$CLJS.bG);b=$CLJS.J.g(c,$CLJS.TJ);c=$CLJS.J.g(c,$CLJS.ci);a=new $CLJS.h(null,3,[$CLJS.w0,$CLJS.WA(a),$CLJS.mE,l2(a,b),$CLJS.a0,k2(a,b)],null);return $CLJS.n(c)?$CLJS.R.j(a,$CLJS.ci,!0):a});