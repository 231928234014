var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./medley.core.js");require("./metabase.lib.aggregation.js");require("./metabase.lib.binning.js");require("./metabase.lib.convert.js");require("./metabase.lib.drill_thru.common.js");require("./metabase.lib.filter.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.drill_thru.js");require("./metabase.lib.types.isa.js");require("./metabase.lib.underlying.js");require("./metabase.lib.util.js");require("./metabase.util.malli.js");
'use strict';var Vra,Wra,T7,U7,Xra;Vra=function(a){if("string"===typeof a)return $CLJS.nh(/[\x00-\x20]*[+-]?NaN[\x00-\x20]*/,a)?NaN:$CLJS.nh(/[\x00-\x20]*[+-]?(Infinity|((\d+\.?\d*|\.\d+)([eE][+-]?\d+)?)[dDfF]?)[\x00-\x20]*/,a)?parseFloat(a):null;throw Error($CLJS.km(a));};$CLJS.R7=function(a){return $CLJS.Xa(a)?$CLJS.Tz(a,$CLJS.H([$CLJS.yi,!0])):a};
Wra=function(a,b,c){var d=Vra(((b-a)/c).toFixed(5)),e=Math.pow(10,0===d?0:Math.floor(Math.log($CLJS.wA(d))/Math.log(10)));return $CLJS.Re(function(f){f*=e;return f>=d?f:null},$CLJS.uqa)};
$CLJS.S7=function(a,b){var c=$CLJS.m7(a);if($CLJS.n(c)){var d=$CLJS.DE.h(c);d=d instanceof $CLJS.M?d.T:null;switch(d){case "num-bins":return a=$CLJS.yA(a,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.RN,$CLJS.mj,$CLJS.Pj],null)),$CLJS.n(a)?(d=$CLJS.O(a),a=$CLJS.J.g(d,$CLJS.$n),d=$CLJS.J.g(d,$CLJS.jk),c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.kE),c=Wra(a,d,c),new $CLJS.h(null,3,[$CLJS.CE,c,$CLJS.EL,b,$CLJS.JL,b+c],null)):null;case "bin-width":return c=$CLJS.O(c),c=$CLJS.J.g(c,$CLJS.CE),new $CLJS.h(null,3,[$CLJS.CE,c,
$CLJS.EL,b,$CLJS.JL,b+c],null);case "default":return null;default:throw Error(["No matching clause: ",$CLJS.p.h(d)].join(""));}}else return null};T7=function(a){var b=$CLJS.eA.h($CLJS.s1($CLJS.S_(a)));b=b.h?b.h($CLJS.Q7):b.call(null,$CLJS.Q7);if($CLJS.n(b))a:for(b=a;;)if($CLJS.td($CLJS.G4.g(b,-1))&&$CLJS.td($CLJS.U5.g(b,-1))){if(b=$CLJS.Qk.j(b,$CLJS.oL,$CLJS.sd),!$CLJS.y($CLJS.oL.h(b))){b=null;break a}}else break a;else b=null;return $CLJS.n(b)?b:a};
U7=function(a,b){var c=T7(a);if($CLJS.E.g(a,c))return b;for(;;){if($CLJS.E.g(a,c))return $CLJS.Fk.l(b,$CLJS.HW,$CLJS.H([$CLJS.NW]));var d=$CLJS.H0,e=d.j,f=a;var k=a;var l=$CLJS.Z1(k,-1);k=$CLJS.n(l)?$CLJS.CW(k,l):null;d=e.call(d,f,-2,k);b=$CLJS.j2.v(a,-2,$CLJS.oW(b),d);if($CLJS.n(b))a=$CLJS.Qk.j(a,$CLJS.oL,$CLJS.sd);else return null}};
Xra=function(a,b,c){var d=function(){if($CLJS.n($CLJS.m7(b))){var e=$CLJS.S7(b,c);if($CLJS.n(e)){var f=$CLJS.O(e);e=$CLJS.J.g(f,$CLJS.EL);f=$CLJS.J.g(f,$CLJS.JL);var k=$CLJS.u5(b,null);e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Y1($CLJS.Qr,new $CLJS.P(null,2,5,$CLJS.Q,[k,e],null)),$CLJS.Y1($CLJS.Sr,new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null))],null)}else e=null}else e=null;return $CLJS.n(e)?e:new $CLJS.P(null,1,5,$CLJS.Q,[(0,$CLJS.q3)(b,c)],null)}();return $CLJS.fb(function(e,f){return $CLJS.r3.j(e,-1,f)},
a,d)};$CLJS.b6.m(null,$CLJS.V6,function(a,b,c){b=$CLJS.O(c);a=$CLJS.J.g(b,$CLJS.Q6);b=$CLJS.J.g(b,$CLJS.LZ);return new $CLJS.h(null,3,[$CLJS.mj,$CLJS.V6,$CLJS.Q6,a,$CLJS.LZ,b],null)});
$CLJS.a6.m(null,$CLJS.V6,function(){function a(c,d,e,f){var k=null;if(3<arguments.length){k=0;for(var l=Array(arguments.length-3);k<l.length;)l[k]=arguments[k+3],++k;k=new $CLJS.w(l,0,null)}return b.call(this,c,d,e,k)}function b(c,d,e){var f=$CLJS.O(e),k=$CLJS.J.g(f,$CLJS.h6),l=$CLJS.J.g(f,$CLJS.FL),m=T7(c),t=$CLJS.OW.l(m,-1,$CLJS.Fk,$CLJS.H([$CLJS.YF,$CLJS.XF,$CLJS.NR,$CLJS.kS,$CLJS.SE]));d=$CLJS.fb(function(v,x){var z=$CLJS.O(x);x=$CLJS.J.g(z,$CLJS.Ti);z=$CLJS.J.g(z,$CLJS.Dj);return Xra(v,x,z)},
t,function(){return function z(x){return new $CLJS.ne(null,function(){for(var C=x;;){var G=$CLJS.y(C);if(G){var K=G;if($CLJS.Ad(K)){var S=$CLJS.lc(K),V=$CLJS.D(S),Z=$CLJS.qe(V);return function(){for(var ra=0;;)if(ra<V){var Na=$CLJS.kd(S,ra);Na=$CLJS.Qk.j(Na,$CLJS.Ti,function(){return function(zb){return U7(c,zb)}}(ra,C,Na,S,V,Z,K,G,m,t,e,f,k,l));$CLJS.Wk.g($CLJS.PK.h($CLJS.Ti.h(Na)),$CLJS.hL)&&Z.add(Na);ra+=1}else return!0}()?$CLJS.te($CLJS.ve(Z),z($CLJS.mc(K))):$CLJS.te($CLJS.ve(Z),null)}var ha=
$CLJS.A(K);ha=$CLJS.Qk.j(ha,$CLJS.Ti,function(){return function(ra){return U7(c,ra)}}(C,ha,K,G,m,t,e,f,k,l));if($CLJS.Wk.g($CLJS.PK.h($CLJS.Ti.h(ha)),$CLJS.hL))return $CLJS.ee(ha,z($CLJS.Lc(K)));C=$CLJS.Lc(K)}else return null}},null,null)}(l)}());var u=function(){var v=$CLJS.id(k);return $CLJS.n(v)?$CLJS.tH(function(x){return $CLJS.E.g($CLJS.D5(x),v)},$CLJS.G4.g(m,-1)):null}();return $CLJS.fb(function(v,x){return $CLJS.r3.j(v,-1,x)},d,$CLJS.n(u)?function(){var v=$CLJS.A(u);switch(v instanceof $CLJS.M?
v.T:null){case "sum-where":case "count-where":case "share":return new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.id(u)],null);case "metric":return $CLJS.s3.g($CLJS.R.j($CLJS.WY.h((0,$CLJS.IY)($CLJS.X1($CLJS.R7(new $CLJS.h(null,2,[$CLJS.mj,$CLJS.FF,$CLJS.FF,$CLJS.R7($CLJS.DZ.h($CLJS.W_(c,$CLJS.id(u))))],null)),$CLJS.mj,$CLJS.FF))),$CLJS.TP,$CLJS.TP.h(c)),-1);default:return null}}():null)}a.A=3;a.B=function(c){var d=$CLJS.A(c);c=$CLJS.B(c);var e=$CLJS.A(c);c=$CLJS.B(c);var f=$CLJS.A(c);c=$CLJS.Lc(c);return b(d,
e,f,c)};a.l=b;return a}());