var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';$CLJS.RJ=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.SJ=new $CLJS.M("operator","filter","operator/filter",-1518842858);$CLJS.TJ=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var UJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ws,$CLJS.ps],null)),VJ=null,WJ=0,XJ=0;;)if(XJ<WJ){var fha=VJ.X(null,XJ);$CLJS.zF(fha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)],null)],null)]));XJ+=1}else{var YJ=$CLJS.y(UJ);if(YJ){var ZJ=YJ;if($CLJS.Ad(ZJ)){var $J=$CLJS.lc(ZJ),gha=$CLJS.mc(ZJ),
hha=$J,iha=$CLJS.D($J);UJ=gha;VJ=hha;WJ=iha}else{var jha=$CLJS.A(ZJ);$CLJS.zF(jha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)],null)],null)],null)]));UJ=$CLJS.B(ZJ);VJ=null;WJ=0}XJ=0}else break}$CLJS.xF($CLJS.rs,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.XE],null)]));
for(var aK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.zG],null)),bK=null,cK=0,dK=0;;)if(dK<cK){var kha=bK.X(null,dK);$CLJS.zF(kha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null)],null)]));dK+=1}else{var eK=$CLJS.y(aK);if(eK){var fK=eK;if($CLJS.Ad(fK)){var gK=$CLJS.lc(fK),lha=$CLJS.mc(fK),
mha=gK,nha=$CLJS.D(gK);aK=lha;bK=mha;cK=nha}else{var oha=$CLJS.A(fK);$CLJS.zF(oha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ds,new $CLJS.h(null,1,[$CLJS.$n,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.rl,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.bF],null)],null)],null)],null)]));aK=$CLJS.B(fK);bK=null;cK=0}dK=0}else break}
for(var hK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Sr,$CLJS.Ur,$CLJS.Or,$CLJS.Qr],null)),iK=null,jK=0,kK=0;;)if(kK<jK){var pha=iK.X(null,kK);$CLJS.xF(pha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)]));kK+=1}else{var lK=$CLJS.y(hK);if(lK){var mK=lK;if($CLJS.Ad(mK)){var nK=$CLJS.lc(mK),qha=$CLJS.mc(mK),rha=nK,sha=$CLJS.D(nK);hK=qha;iK=rha;jK=sha}else{var tha=$CLJS.A(mK);$CLJS.xF(tha,$CLJS.H([$CLJS.ht,$CLJS.Lj,
new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)]));hK=$CLJS.B(mK);iK=null;jK=0}kK=0}else break}$CLJS.xF($CLJS.LF,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)]));
$CLJS.xF($CLJS.RF,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.dF],null)]));
for(var oK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.KF,$CLJS.VF],null)),pK=null,qK=0,rK=0;;)if(rK<qK){var uha=pK.X(null,rK);$CLJS.xF(uha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)]));rK+=1}else{var sK=$CLJS.y(oK);if(sK){var tK=sK;if($CLJS.Ad(tK)){var uK=$CLJS.lc(tK),vha=$CLJS.mc(tK),wha=uK,xha=$CLJS.D(uK);oK=vha;pK=wha;qK=xha}else{var yha=$CLJS.A(tK);$CLJS.xF(yha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.YE],null)]));oK=$CLJS.B(tK);
pK=null;qK=0}rK=0}else break}
for(var vK=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.MF,$CLJS.TF],null)),wK=null,xK=0,yK=0;;)if(yK<xK){var zha=wK.X(null,yK);$CLJS.xF(zha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)]));yK+=1}else{var zK=$CLJS.y(vK);if(zK){var AK=zK;if($CLJS.Ad(AK)){var BK=$CLJS.lc(AK),Aha=$CLJS.mc(AK),Bha=BK,Cha=$CLJS.D(BK);vK=Aha;wK=Bha;xK=Cha}else{var Dha=$CLJS.A(AK);$CLJS.xF(Dha,$CLJS.H([$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fF],null)]));vK=$CLJS.B(AK);
wK=null;xK=0}yK=0}else break}
for(var CK=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qG,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.Fs],null)],null),DK=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.$F,$CLJS.tG,$CLJS.hG,$CLJS.aG],null)),EK=null,FK=0,GK=0;;)if(GK<FK){var HK=EK.X(null,GK);$CLJS.IG.v(HK,$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,HK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.xE,CK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,
$CLJS.gF],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null)],null));GK+=1}else{var IK=$CLJS.y(DK);if(IK){var JK=IK;if($CLJS.Ad(JK)){var KK=$CLJS.lc(JK),Eha=$CLJS.mc(JK),Fha=KK,Gha=$CLJS.D(KK);DK=Eha;EK=Fha;FK=Gha}else{var LK=$CLJS.A(JK);$CLJS.IG.v(LK,$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,LK],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.xE,CK],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.gF],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.W,$CLJS.gF],null)],null));DK=$CLJS.B(JK);EK=null;FK=0}GK=0}else break}
$CLJS.IG.v($CLJS.uG,$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.uG],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ot,$CLJS.xE,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.gk,new $CLJS.h(null,1,[$CLJS.Js,!0],null),$CLJS.Fs],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.VE],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ms,$CLJS.Sv,$CLJS.Ux,$CLJS.QF],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.W,$CLJS.jF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.W,$CLJS.fE],null)],null));$CLJS.IG.v($CLJS.HF,$CLJS.ht,$CLJS.Lj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ss,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.HF],null),$CLJS.xE,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ps,$CLJS.jE,$CLJS.sE],null)],null));
$CLJS.X($CLJS.RJ,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.ik,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vt,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tk,$CLJS.SJ],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.bG,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.ms,$CLJS.tk,$CLJS.zG,$CLJS.RF,$CLJS.LF,$CLJS.Sr,$CLJS.Or,$CLJS.Ur,$CLJS.Qr,$CLJS.KF,$CLJS.VF,$CLJS.MF,$CLJS.TF,$CLJS.hG,$CLJS.aG,$CLJS.$F,$CLJS.tG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.TJ,$CLJS.Xi],null)],null));