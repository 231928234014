var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.data.js");require("./clojure.set.js");require("./clojure.string.js");require("./malli.core.js");require("./malli.error.js");require("./medley.core.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.options.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.ref.js");require("./metabase.lib.util.js");require("./metabase.mbql.normalize.js");require("./metabase.util.js");require("./metabase.util.log.js");require("./metabase.util.malli.js");
'use strict';var zY,ema,BY,fma,gma,hma,DY,jma,kma,lma,mma,GY,JY,KY,LY,NY,oma,OY,PY,pma,qma,rma,FY,ima,QY,RY,nma,TY;zY=function(a,b){var c=$CLJS.y(b);for(a=$CLJS.y($CLJS.bf(a,b));;)if(a)c=$CLJS.B(c),a=$CLJS.B(a);else return c};
ema=function(){return function(){function a(k,l,m){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.j?$CLJS.mj.j(k,l,m):$CLJS.mj.call(null,k,l,m),$CLJS.nl.j?$CLJS.nl.j(k,l,m):$CLJS.nl.call(null,k,l,m)],null)}function b(k,l){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.g?$CLJS.mj.g(k,l):$CLJS.mj.call(null,k,l),$CLJS.nl.g?$CLJS.nl.g(k,l):$CLJS.nl.call(null,k,l)],null)}function c(k){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.h?$CLJS.mj.h(k):$CLJS.mj.call(null,k),$CLJS.nl.h?$CLJS.nl.h(k):$CLJS.nl.call(null,
k)],null)}function d(){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.mj.o?$CLJS.mj.o():$CLJS.mj.call(null),$CLJS.nl.o?$CLJS.nl.o():$CLJS.nl.call(null)],null)}var e=null,f=function(){function k(m,t,u,v){var x=null;if(3<arguments.length){x=0;for(var z=Array(arguments.length-3);x<z.length;)z[x]=arguments[x+3],++x;x=new $CLJS.w(z,0,null)}return l.call(this,m,t,u,x)}function l(m,t,u,v){return new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Se.N($CLJS.mj,m,t,u,v),$CLJS.Se.N($CLJS.nl,m,t,u,v)],null)}k.A=3;k.B=function(m){var t=
$CLJS.A(m);m=$CLJS.B(m);var u=$CLJS.A(m);m=$CLJS.B(m);var v=$CLJS.A(m);m=$CLJS.Lc(m);return l(t,u,v,m)};k.l=l;return k}();e=function(k,l,m,t){switch(arguments.length){case 0:return d.call(this);case 1:return c.call(this,k);case 2:return b.call(this,k,l);case 3:return a.call(this,k,l,m);default:var u=null;if(3<arguments.length){u=0;for(var v=Array(arguments.length-3);u<v.length;)v[u]=arguments[u+3],++u;u=new $CLJS.w(v,0,null)}return f.l(k,l,m,u)}throw Error("Invalid arity: "+arguments.length);};e.A=
3;e.B=f.B;e.o=d;e.h=c;e.g=b;e.j=a;e.l=f.l;return e}()};
$CLJS.AY=function(a){return function(b){var c=$CLJS.$e(-1);return function(){function d(l,m){var t=c.Dd(null,c.Qb(null)+1);m=a.g?a.g(t,m):a.call(null,t,m);return null==m?l:b.g?b.g(l,m):b.call(null,l,m)}function e(l){return b.h?b.h(l):b.call(null,l)}function f(){return b.o?b.o():b.call(null)}var k=null;k=function(l,m){switch(arguments.length){case 0:return f.call(this);case 1:return e.call(this,l);case 2:return d.call(this,l,m)}throw Error("Invalid arity: "+arguments.length);};k.o=f;k.h=e;k.g=d;return k}()}};
BY=function(a){return $CLJS.OL($CLJS.jW)(a,$CLJS.Cf,$CLJS.Cf)};fma=function(a,b,c){return $CLJS.fb(function(d,e){var f=$CLJS.I(e,0,null);e=$CLJS.I(e,1,null);return null==e?d:$CLJS.R.j(d,f,e)},null==b?a:$CLJS.R.j(a,$CLJS.YF,b),$CLJS.nt(2,2,c))};gma=function(a,b){var c=$CLJS.dm(a,$CLJS.FQ);if($CLJS.n(c)){var d=$CLJS.R.j;c=$CLJS.Fb(c);b=$CLJS.wt.g?$CLJS.wt.g(c,b):$CLJS.wt.call(null,c,b);a=d.call($CLJS.R,a,$CLJS.FQ,b)}return a};hma=function(a){return $CLJS.gf.g($CLJS.FV(a),$CLJS.IV(a))};
$CLJS.CY=function(a,b){return $CLJS.tW(a)&&$CLJS.E.g($CLJS.A(a),b)};DY=function(a,b,c){var d=new $CLJS.ah(null,new $CLJS.h(null,2,[$CLJS.Ms,null,$CLJS.ul,null],null),null),e=$CLJS.n(d.h?d.h(b):d.call(null,b))?$CLJS.tu(2,c):$CLJS.tu(1,c);b=$CLJS.n(d.h?d.h(b):d.call(null,b))?zY(2,c):zY(1,c);var f=$CLJS.I(b,0,null);return $CLJS.y(e)?$CLJS.CX.j(a,e,function(k){var l=$CLJS.R.j(k,f,null);return $CLJS.zd(k)?$CLJS.eg.j($CLJS.Cf,$CLJS.Ok.h($CLJS.yl),l):$CLJS.xd(k)?$CLJS.CA($CLJS.Wa,l):l}):$CLJS.Fk.g(a,f)};
jma=function(a){return $CLJS.fb(function(b,c){var d=$CLJS.I(c,0,null);$CLJS.I(c,1,null);return DY(b,ima,d)},a,hma(a))};
kma=function(a){for(var b=0;;){var c=$CLJS.F($CLJS.oL.h(a),b);a:{var d=c;for(var e=$CLJS.Cf;;){var f=$CLJS.A($CLJS.hf.g(ema(),$CLJS.lf($CLJS.Nk.j(EY,$CLJS.A,$CLJS.nl),$CLJS.Qs.h(BY(d)))));if($CLJS.n(f)){var k=f;f=$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);var l=DY(d,f,k),m=$CLJS.FA($CLJS.Dy);if($CLJS.n($CLJS.EA("metabase.lib.convert",m))){var t=$CLJS.zB.l($CLJS.H([$CLJS.yz,$CLJS.Mh.l($CLJS.H([k]))])),u=$CLJS.zB,v=u.l,x=$CLJS.Mh,z=x.l;var C=f;C=$CLJS.n(C)?C:$CLJS.TL(BY(d));$CLJS.OA("metabase.lib.convert",
m,$CLJS.rW("Clean: Removing bad clause in %s due to error %s:\n%s",$CLJS.H([t,v.call(u,$CLJS.H([$CLJS.yz,z.call(x,$CLJS.H([C]))])),$CLJS.zB.l($CLJS.H([$CLJS.nz,$CLJS.sW($CLJS.A($CLJS.Zt(d,l)))]))])),null)}if($CLJS.E.g(l,d))break a;d=l;e=$CLJS.be.g(e,new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null))}else break a}}d=jma(d);if($CLJS.E.g(c,d)){if($CLJS.E.g(b,$CLJS.D($CLJS.oL.h(a))-1))return a;b+=1}else a=$CLJS.Qk.N(a,$CLJS.oL,$CLJS.R,b,d)}};
lma=function(a){var b=$CLJS.GW();return $CLJS.Pk.g(function(c){return $CLJS.E.g($CLJS.WE.h(c),"__join")?$CLJS.Qk.j(c,$CLJS.WE,b):c},a)};mma=function(a){return"string"===typeof $CLJS.dP.h(a)?$CLJS.Fk.g($CLJS.R.j(a,$CLJS.JV,$CLJS.EW($CLJS.dP.h(a))),$CLJS.dP):a};GY=function(a,b){var c=$CLJS.J.j(a,b,FY);if($CLJS.E.g(c,FY))throw $CLJS.hi(["Unable to find ",$CLJS.Mh.l($CLJS.H([b]))," in map."].join(""),new $CLJS.h(null,2,[nma,a,$CLJS.Uw,b],null));return c};
JY=function(){return $CLJS.Ok.h(function(a){var b=$CLJS.I(a,0,null);$CLJS.I(a,1,null);(a=$CLJS.me(b))?(b=$CLJS.ie(b),b=$CLJS.n(b)?$CLJS.E.g(b,"lib")||0==b.lastIndexOf("metabase.lib.",0):null):b=a;return b})};KY=function(a){return $CLJS.eg.j($CLJS.N,JY(),a)};LY=function(a){return $CLJS.Fe($CLJS.eg.j($CLJS.N,$CLJS.Nk.g(JY(),$CLJS.Ok.h(function(b){var c=$CLJS.I(b,0,null);$CLJS.I(b,1,null);return $CLJS.E.g(c,$CLJS.Ei)})),a))};
NY=function(a){var b=$CLJS.y(a);a=$CLJS.A(b);var c=$CLJS.B(b);b=$CLJS.A(c);c=$CLJS.B(c);var d=$CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[a],null),$CLJS.hf.h($CLJS.MY),c);a=$CLJS.E.g(a,$CLJS.SF)&&$CLJS.B(c)?$CLJS.be.g($CLJS.sd(d),new $CLJS.h(null,1,[$CLJS.ci,$CLJS.rd(d)],null)):d;b=$CLJS.Fe(LY(b));return $CLJS.n(b)?new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.tM,a,b],null):a};oma=function(a){return $CLJS.eg.j($CLJS.Cf,$CLJS.Nk.g($CLJS.hf.h(function(b){return $CLJS.zA(b,$CLJS.EB)}),$CLJS.hf.h($CLJS.MY)),$CLJS.yW.h(a))};
OY=function(a){a=$CLJS.O(a);a=$CLJS.J.g(a,$CLJS.oL);a=$CLJS.A($CLJS.fb(function(b,c){var d=$CLJS.I(b,0,null),e=$CLJS.I(b,1,null);b=$CLJS.Q;var f=$CLJS.MY.h(c);d=$CLJS.n(d)?$CLJS.R.j(f,$CLJS.HQ,d):f;d=$CLJS.n(e)?$CLJS.R.j(d,$CLJS.QO,oma(e)):d;return new $CLJS.P(null,2,5,b,[d,$CLJS.mV.h(c)],null)},null,a));return $CLJS.n($CLJS.hQ.h(a))?$CLJS.GV(a,new $CLJS.h(null,1,[$CLJS.hQ,$CLJS.FF],null)):a};
PY=function(a,b,c){var d=$CLJS.E.g($CLJS.D($CLJS.J.g(a,b)),1)?$CLJS.aX(a,b,$CLJS.Nk.g($CLJS.MY,$CLJS.A)):a;a=1<$CLJS.D($CLJS.J.g(a,b))?$CLJS.aX(d,b,function(e){return $CLJS.eg.j(new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.ws],null),$CLJS.hf.h($CLJS.MY),e)}):d;return $CLJS.GV(a,$CLJS.Ee([b,c]))};pma=function(a){var b=$CLJS.JV.h(a);return $CLJS.n(b)?$CLJS.R.j($CLJS.Fk.g(a,$CLJS.JV),$CLJS.dP,["card__",$CLJS.p.h(b)].join("")):a};
qma=new $CLJS.M(null,"legacy-index-\x3epMBQL-uuid","legacy-index-\x3epMBQL-uuid",-192973336);rma=new $CLJS.M(null,"legacy-ref","legacy-ref",1686404700);FY=new $CLJS.M("metabase.lib.convert","not-found","metabase.lib.convert/not-found",967996550);ima=new $CLJS.M("metabase.lib.schema","invalid-ref","metabase.lib.schema/invalid-ref",-2010445475);QY=new $CLJS.M("metabase.lib.convert","aggregation-or-expression","metabase.lib.convert/aggregation-or-expression",1924029672);
RY=new $CLJS.M("metabase.lib.convert","expression","metabase.lib.convert/expression",1648102782);$CLJS.SY=new $CLJS.M("lib.convert","converted?","lib.convert/converted?",1937433683);nma=new $CLJS.M(null,"m","m",1632677161);TY=new $CLJS.M("metabase.lib.convert","aggregation","metabase.lib.convert/aggregation",108290790);var EY,sma,tma,uma,vma,wma,xma,yma,zma,Ama,Bma;$CLJS.UY=$CLJS.N;$CLJS.VY=$CLJS.N;EY=new $CLJS.ah(null,new $CLJS.h(null,7,[$CLJS.gW,null,$CLJS.SE,null,$CLJS.CN,null,$CLJS.YP,null,$CLJS.XF,null,$CLJS.NR,null,$CLJS.YF,null],null),null);sma=$CLJS.Xe($CLJS.N);tma=$CLJS.Xe($CLJS.N);uma=$CLJS.Xe($CLJS.N);vma=$CLJS.Xe($CLJS.N);wma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.fC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));
$CLJS.WY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3epMBQL"),$CLJS.dC,wma,sma,tma,uma,vma);$CLJS.WY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){var b=$CLJS.rd(a);var c=$CLJS.xd(b);a=c?$CLJS.sd(a):a;var d=$CLJS.y(a);a=$CLJS.A(d);d=$CLJS.B(d);b=$CLJS.nW($CLJS.eg.j(new $CLJS.P(null,2,5,$CLJS.Q,[a,c?b:$CLJS.N],null),$CLJS.hf.h($CLJS.WY),d))}else b=a;return b});$CLJS.WY.m(null,$CLJS.fW,function(a){return a});
$CLJS.WY.m(null,$CLJS.TV,function(a){var b=$CLJS.WY.h($CLJS.YF.h(a)),c=$CLJS.Fe($CLJS.Pk.g(function(t){var u=$CLJS.I(t,0,null);t=$CLJS.I(t,1,null);return $CLJS.vW($CLJS.WY.h(t),u)},$CLJS.YP.h(a))),d=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[t,u],null)}),b),e=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(t,u){$CLJS.I(u,0,null);u=$CLJS.I(u,1,null);u=$CLJS.O(u);u=$CLJS.J.g(u,$CLJS.zE);return new $CLJS.P(null,
2,5,$CLJS.Q,[u,t],null)}),b),f=$CLJS.VY,k=$CLJS.UY;$CLJS.VY=d;$CLJS.UY=e;try{var l=fma(mma(a),b,$CLJS.H([$CLJS.YP,c])),m=$CLJS.fb(function(t,u){return $CLJS.Va($CLJS.J.g(t,u))?t:$CLJS.Qk.j(t,u,$CLJS.WY)},l,$CLJS.Gk.l(EY,$CLJS.YF,$CLJS.H([$CLJS.YP])));return $CLJS.n($CLJS.CN.h(m))?$CLJS.Qk.j(m,$CLJS.CN,lma):m}finally{$CLJS.UY=k,$CLJS.VY=f}});$CLJS.WY.m(null,$CLJS.$V,function(a){return gma(a,function(b){return $CLJS.aX(b,$CLJS.CF,$CLJS.WY)})});
$CLJS.WY.m(null,$CLJS.jL,function(a){a=$CLJS.Qk.j($CLJS.Qk.j(a,$CLJS.rL,$CLJS.WY),$CLJS.oL,$CLJS.WY);var b=$CLJS.n($CLJS.SE.h(a))?$CLJS.Qk.j(a,$CLJS.SE,function(c){return $CLJS.Ol(c)?$CLJS.Pk.g($CLJS.WY,c):$CLJS.zh.h(c)}):a;return $CLJS.Va($CLJS.WE.h(a))?$CLJS.R.j(b,$CLJS.WE,"__join"):b});$CLJS.WY.m(null,$CLJS.iB,function(a){return $CLJS.Pk.g($CLJS.WY,a)});
$CLJS.WY.m(null,$CLJS.lB,function(a){return $CLJS.n($CLJS.mj.h(a))?kma($CLJS.R.j($CLJS.Qk.j($CLJS.AW(a),$CLJS.oL,function(b){return $CLJS.Pk.g($CLJS.WY,b)}),$CLJS.SY,!0)):$CLJS.wt(a,$CLJS.WY)});$CLJS.WY.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null):new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,a,b],null))});
$CLJS.WY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=$CLJS.GV(a,new $CLJS.h(null,3,[$CLJS.eD,$CLJS.pB,$CLJS.CO,$CLJS.Vj,$CLJS.VR,$CLJS.uE],null));var c=$CLJS.R.j;var d=$CLJS.Ei.h(a);$CLJS.n(d)||(d=$CLJS.pB.h(a),d=$CLJS.n(d)?d:$CLJS.ME(b));a=c.call($CLJS.R,a,$CLJS.Ei,d);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null))});
$CLJS.WY.m(null,$CLJS.SF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);a=$CLJS.ci.h(c);b=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.SF,$CLJS.Fk.g(c,$CLJS.ci),$CLJS.Pk.g($CLJS.WY,b)],null);b=$CLJS.nW(b);return null!=a?$CLJS.be.g(b,$CLJS.WY.h(a)):b});$CLJS.WY.m(null,$CLJS.mB,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,c],null))});
$CLJS.WY.m(null,$CLJS.YF,function(a){var b=$CLJS.I(a,0,null),c=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);return $CLJS.nW(new $CLJS.P(null,3,5,$CLJS.Q,[b,a,GY($CLJS.VY,c)],null))});$CLJS.WY.m(null,$CLJS.tM,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=$CLJS.WY.h(b);var c=$CLJS.y(b);b=$CLJS.A(c);var d=$CLJS.B(c);c=$CLJS.A(d);d=$CLJS.B(d);return $CLJS.eg.g(new $CLJS.P(null,2,5,$CLJS.Q,[b,$CLJS.ll.l($CLJS.H([c,a]))],null),d)});xma=$CLJS.Xe($CLJS.N);yma=$CLJS.Xe($CLJS.N);
zma=$CLJS.Xe($CLJS.N);Ama=$CLJS.Xe($CLJS.N);Bma=$CLJS.J.j(new $CLJS.h(null,1,[$CLJS.hj,$CLJS.fC],null),$CLJS.hj,$CLJS.Vh.o?$CLJS.Vh.o():$CLJS.Vh.call(null));$CLJS.MY=new $CLJS.di($CLJS.Fh.g("metabase.lib.convert","-\x3elegacy-MBQL"),$CLJS.dC,Bma,xma,yma,zma,Ama);
$CLJS.MY.m(null,$CLJS.ci,function(a){if($CLJS.zd(a)&&$CLJS.A(a)instanceof $CLJS.M){a=$CLJS.y(a);var b=$CLJS.A(a),c=$CLJS.B(a);a=$CLJS.A(c);var d=$CLJS.B(c);if($CLJS.xd(a)){c=$CLJS.eg.g;b=new $CLJS.P(null,1,5,$CLJS.Q,[b],null);var e=$CLJS.gf.g;d=$CLJS.hf.g($CLJS.MY,d);a=LY(a);a=c.call($CLJS.eg,b,e.call($CLJS.gf,d,$CLJS.n(a)?new $CLJS.P(null,1,5,$CLJS.Q,[a],null):null))}else a=$CLJS.eg.g(new $CLJS.P(null,1,5,$CLJS.Q,[b],null),$CLJS.hf.g($CLJS.MY,$CLJS.ee(a,d)))}else a=$CLJS.xd(a)?$CLJS.wt(KY(a),$CLJS.MY):
a;return a});for(var XY=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[TY,RY],null)),YY=null,ZY=0,$Y=0;;)if($Y<ZY){var Cma=YY.X(null,$Y);$CLJS.qF(Cma,QY);$Y+=1}else{var aZ=$CLJS.y(XY);if(aZ){var bZ=aZ;if($CLJS.Ad(bZ)){var cZ=$CLJS.lc(bZ),Dma=$CLJS.mc(bZ),Ema=cZ,Fma=$CLJS.D(cZ);XY=Dma;YY=Ema;ZY=Fma}else{var Gma=$CLJS.A(bZ);$CLJS.qF(Gma,QY);XY=$CLJS.B(bZ);YY=null;ZY=0}$Y=0}else break}
for(var dZ=$CLJS.y(new $CLJS.P(null,12,5,$CLJS.Q,[$CLJS.Px,$CLJS.cG,$CLJS.rG,$CLJS.OF,$CLJS.jk,$CLJS.wG,$CLJS.$n,$CLJS.UF,$CLJS.pG,$CLJS.xG,$CLJS.vG,$CLJS.CG],null)),eZ=null,fZ=0,gZ=0;;)if(gZ<fZ){var Hma=eZ.X(null,gZ);$CLJS.qF(Hma,TY);gZ+=1}else{var hZ=$CLJS.y(dZ);if(hZ){var iZ=hZ;if($CLJS.Ad(iZ)){var jZ=$CLJS.lc(iZ),Ima=$CLJS.mc(iZ),Jma=jZ,Kma=$CLJS.D(jZ);dZ=Ima;eZ=Jma;fZ=Kma}else{var Lma=$CLJS.A(iZ);$CLJS.qF(Lma,TY);dZ=$CLJS.B(iZ);eZ=null;fZ=0}gZ=0}else break}
for(var kZ=$CLJS.y($CLJS.yf([$CLJS.Wr,$CLJS.ht,$CLJS.Xr,$CLJS.DG,$CLJS.SF,$CLJS.lG,$CLJS.JF,$CLJS.FG,$CLJS.AF,$CLJS.WF,$CLJS.EF,$CLJS.gG,$CLJS.nG,$CLJS.GF,$CLJS.ZI,$CLJS.aJ,$CLJS.Ey,$CLJS.VI,$CLJS.cJ,$CLJS.PI,$CLJS.gJ,$CLJS.UI,$CLJS.NI,$CLJS.fJ,$CLJS.nJ,$CLJS.jJ,$CLJS.TI,$CLJS.qJ,$CLJS.iJ,$CLJS.oJ,$CLJS.IF,$CLJS.BF,$CLJS.GG,$CLJS.ZH,$CLJS.kG,$CLJS.Nx,$CLJS.yG,$CLJS.AG,$CLJS.HG,$CLJS.yt,$CLJS.Ht],!0)),lZ=null,mZ=0,nZ=0;;)if(nZ<mZ){var Mma=lZ.X(null,nZ);$CLJS.qF(Mma,RY);nZ+=1}else{var oZ=$CLJS.y(kZ);
if(oZ){var pZ=oZ;if($CLJS.Ad(pZ)){var qZ=$CLJS.lc(pZ),Nma=$CLJS.mc(pZ),Oma=qZ,Pma=$CLJS.D(qZ);kZ=Nma;lZ=Oma;mZ=Pma}else{var Qma=$CLJS.A(pZ);$CLJS.qF(Qma,RY);kZ=$CLJS.B(pZ);lZ=null;mZ=0}nZ=0}else break}$CLJS.MY.m(null,QY,function(a){return NY(a)});$CLJS.MY.m(null,$CLJS.lB,function(a){return $CLJS.eg.j($CLJS.N,$CLJS.Nk.g(JY(),$CLJS.hf.h(function(b){var c=$CLJS.I(b,0,null);b=$CLJS.I(b,1,null);return new $CLJS.P(null,2,5,$CLJS.Q,[c,$CLJS.MY.h(b)],null)})),a)});
$CLJS.MY.m(null,$CLJS.YF,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null),c=$CLJS.I(a,2,null);if($CLJS.xd(b))try{var d=LY(b),e=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.YF,GY($CLJS.UY,c)],null);return $CLJS.n(d)?$CLJS.be.g(e,d):e}catch(f){throw b=f,c=$CLJS.AV(b),c=$CLJS.fa.g?$CLJS.fa.g("Error converting aggregation reference to pMBQL: %s",c):$CLJS.fa.call(null,"Error converting aggregation reference to pMBQL: %s",c),$CLJS.gi(c,new $CLJS.h(null,1,[$CLJS.W,a],null),b);}else return a});
$CLJS.MY.m(null,$CLJS.iB,function(a){return $CLJS.Pk.g($CLJS.MY,a)});$CLJS.MY.m(null,$CLJS.iG,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);a=null==b||$CLJS.xd(b)?new $CLJS.P(null,2,5,$CLJS.Q,[b,a],null):new $CLJS.P(null,2,5,$CLJS.Q,[a,b],null);b=$CLJS.I(a,0,null);a=$CLJS.I(a,1,null);return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.iG,$CLJS.MY.h(a),LY(b)],null)});
$CLJS.MY.m(null,$CLJS.Dj,function(a){$CLJS.I(a,0,null);var b=$CLJS.I(a,1,null);a=$CLJS.I(a,2,null);b=LY($CLJS.GV(b,new $CLJS.h(null,3,[$CLJS.pB,$CLJS.eD,$CLJS.Vj,$CLJS.CO,$CLJS.uE,$CLJS.VR],null)));return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Dj,a,b],null)});
$CLJS.MY.m(null,$CLJS.jL,function(a){var b=KY(a);a=0==$CLJS.WE.h(a).lastIndexOf("__join",0)?$CLJS.Fk.g(b,$CLJS.WE):b;return $CLJS.ll.l($CLJS.H([$CLJS.wt($CLJS.Fk.l(a,$CLJS.oL,$CLJS.H([$CLJS.rL])),$CLJS.MY),PY($CLJS.cm(a,new $CLJS.P(null,1,5,$CLJS.Q,[$CLJS.rL],null)),$CLJS.rL,$CLJS.PR),OY(a)]))});
$CLJS.MY.m(null,$CLJS.TV,function(a){var b=$CLJS.YF.h(a),c=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[f,k],null)}),b);b=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(f,k){$CLJS.I(k,0,null);k=$CLJS.I(k,1,null);k=$CLJS.O(k);k=$CLJS.J.g(k,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[k,f],null)}),b);var d=$CLJS.VY,e=$CLJS.UY;$CLJS.VY=c;$CLJS.UY=b;try{return $CLJS.fb(function(f,k){return $CLJS.aX(f,
k,$CLJS.MY)},PY($CLJS.aX($CLJS.aX(pma(KY(a)),$CLJS.YF,function(f){return $CLJS.Pk.g(NY,f)}),$CLJS.YP,function(f){return $CLJS.eg.g($CLJS.N,function(){return function m(l){return new $CLJS.ne(null,function(){for(;;){var t=$CLJS.y(l);if(t){if($CLJS.Ad(t)){var u=$CLJS.lc(t),v=$CLJS.D(u),x=$CLJS.qe(v);a:for(var z=0;;)if(z<v){var C=$CLJS.kd(u,z),G=$CLJS.MY.h(C);C=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uW(C),$CLJS.E.g($CLJS.Dj,$CLJS.A(G))?$CLJS.hd(G):G],null);x.add(C);z+=1}else{u=!0;break a}return u?$CLJS.te($CLJS.ve(x),
m($CLJS.mc(t))):$CLJS.te($CLJS.ve(x),null)}x=$CLJS.A(t);u=$CLJS.MY.h(x);return $CLJS.ee(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.uW(x),$CLJS.E.g($CLJS.Dj,$CLJS.A(u))?$CLJS.hd(u):u],null),m($CLJS.Lc(t)))}return null}},null,null)}(f)}())}),$CLJS.gW,$CLJS.NF),$CLJS.Gk.l(EY,$CLJS.YF,$CLJS.H([$CLJS.gW,$CLJS.YP])))}finally{$CLJS.UY=e,$CLJS.VY=d}});$CLJS.MY.m(null,$CLJS.$V,function(a){return $CLJS.wt(KY(a),$CLJS.MY)});
$CLJS.MY.m(null,$CLJS.fW,function(a){try{var b=KY(a),c=$CLJS.tN.h(b),d=OY(b),e=$CLJS.E.g($CLJS.Vt.h($CLJS.id($CLJS.oL.h(a))),$CLJS.$V)?$CLJS.hQ:$CLJS.FF;return $CLJS.ll.l($CLJS.H([$CLJS.Fk.l(b,$CLJS.oL,$CLJS.H([$CLJS.tN,$CLJS.SY])),function(){var k=$CLJS.Ee([$CLJS.mj,e,e,d]);return $CLJS.y(c)?$CLJS.R.j(k,$CLJS.tN,c):k}()]))}catch(k){var f=k;throw $CLJS.gi(function(){var l=$CLJS.AV(f);return $CLJS.fa.g?$CLJS.fa.g("Error converting MLv2 query to legacy query: %s",l):$CLJS.fa.call(null,"Error converting MLv2 query to legacy query: %s",
l)}(),new $CLJS.h(null,1,[$CLJS.FF,a],null),f);}});
$CLJS.rZ=function(){function a(d,e,f){f=$CLJS.yY(null,$CLJS.Tz(f,$CLJS.H([$CLJS.yi,!0])));var k=$CLJS.CW(d,e);k=$CLJS.O(k);var l=$CLJS.J.g(k,$CLJS.YF);k=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[v,x],null)}),l);l=$CLJS.eg.j($CLJS.N,$CLJS.hm(function(v,x){$CLJS.I(x,0,null);x=$CLJS.I(x,1,null);x=$CLJS.O(x);x=$CLJS.J.g(x,$CLJS.zE);return new $CLJS.P(null,2,5,$CLJS.Q,[x,v],null)}),l);var m=
$CLJS.VY,t=$CLJS.UY;$CLJS.VY=k;$CLJS.UY=l;try{try{return $CLJS.WY.h(f)}catch(v){var u=v;throw $CLJS.gi(function(){var x=$CLJS.AV(u);return $CLJS.fa.g?$CLJS.fa.g("Error converting legacy ref to pMBQL: %s",x):$CLJS.fa.call(null,"Error converting legacy ref to pMBQL: %s",x)}(),new $CLJS.h(null,4,[$CLJS.FF,d,$CLJS.CL,e,rma,f,qma,$CLJS.VY],null),u);}}finally{$CLJS.UY=t,$CLJS.VY=m}}function b(d,e){return c.j?c.j(d,-1,e):c.call(null,d,-1,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,
d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();